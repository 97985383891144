// This file was automatically generated by generateSchemaData.js

export const paginatedFields: { readonly [key: string]: readonly string[] } = {
	Activity: ['comments'],
	Kit: ['relatedItems'],
	Opportunity: ['comments'],
	Package: ['relatedItems'],
	Product: ['relatedItems'],
	Query: [
		'activities',
		'activityComments',
		'activityStages',
		'adminUsers',
		'adyenConfiguredStorisLocations',
		'allActiveProtectionPlans',
		'appRegistrations',
		'apps',
		'brands',
		'carts',
		'collections',
		'completedSalesOrders',
		'deliveryChargeOverrideReasons',
		'elavonConfiguredStorisLocations',
		'emvTerminals',
		'entraIdConfigurations',
		'exceptionReasons',
		'fiservConfiguredStorisLocations',
		'handlingMethods',
		'itemLocationInventories',
		'items',
		'locations',
		'openSalesOrders',
		'opportunities',
		'opportunityComments',
		'opportunityProjectTypes',
		'opportunityStages',
		'orderComments',
		'productCategories',
		'productGroups',
		'purchaseOrderItems',
		'purchaseOrders',
		'purchaseStatuses',
		'regions',
		'relationshipOpenSalesOrders',
		'relationships',
		'salespeople',
		'suggestions',
		'userGroups',
		'users',
		'vendors',
		'workspaceRoles',
		'workspaces',
	],
};

export const nonPaginatedArrays: { readonly [key: string]: readonly string[] } = {
	AddressValidateResult: ['messages'],
	AddressVerificationServiceSettings: ['availableProviders'],
	AdminUser: ['permissions'],
	AdyenConfiguredStorisLocation: ['adyenTerminals'],
	AlternateTaxInterfaceTestConnectionResult: ['messages'],
	AppRegistration: ['permissions'],
	AutoApplyProtectionPlansResult: ['appliedReferences'],
	Cart: [
		'allocatedOverridableSalesDiscount',
		'exceptions',
		'fulfillments',
		'permissionExceptions',
		'protectionSummary',
		'salespeople',
	],
	CartFulfillment: [
		'availableDates',
		'exceptions',
		'lines',
		'overrideAuthorizationRequestPermissionExceptions',
		'permissionExceptions',
	],
	CartLine: [
		'appliedDiscounts',
		'components',
		'eligibleAsIsPieces',
		'eligibleDiscounts',
		'eligibleProtections',
		'exceptions',
		'overrideAuthorizationRequestPermissionExceptions',
		'permissionExceptions',
		'selectedProtections',
	],
	CartLineComponent: [
		'appliedDiscounts',
		'eligibleDiscounts',
		'eligibleProtections',
		'exceptions',
		'overrideAuthorizationRequestPermissionExceptions',
		'permissionExceptions',
		'selectedProtections',
	],
	CartLineSpecialOrderConfiguration: ['options'],
	CompletedSalesOrder: ['lines'],
	Customer: ['addresses', 'creditCards', 'financeAccountProviders', 'financeAccounts'],
	CustomerAddress: ['emails', 'phones'],
	ElavonConfiguredStorisLocation: ['elavonTerminals'],
	ElavonSettings: ['acceptedAvsResponseCodes', 'acceptedCscResponseCodes'],
	EligibleFinancePlansByProvider: ['eligibleFinancePlans'],
	FinancePaymentPlan: ['financePaymentExceptions'],
	FiservTerminal: ['locationId'],
	GetAndUpdateFiservTerminalsByLocationIdResult: [
		'originalFiservTerminalsList',
		'updatedFiservTerminalsList',
	],
	ItemSpecialOrderTemplate: ['optionTypes'],
	ItemSpecialOrderTemplateOptionType: ['options'],
	Kit: ['collections', 'components', 'families', 'images', 'vendorShipFromOptions'],
	Location: ['enabledPaymentMethods'],
	Me: ['permissions', 'workspaceRoles'],
	Mutation: [
		'cartLineRemove',
		'userAddWorkspaceRole',
		'userRemoveWorkspaceRole',
		'workspaceDisable',
		'workspaceEnable',
	],
	OpenQuote: ['fulfillments', 'lines', 'salespeople'],
	OpenSalesOrder: ['fulfillments', 'lines', 'salespeople'],
	Package: ['collections', 'components', 'families', 'images', 'vendorShipFromOptions'],
	PostalCode: ['deliveryRoutes'],
	Product: ['collections', 'families', 'images', 'vendorShipFromOptions'],
	ProtectionPlan: ['availableLocationIds', 'table'],
	PurchaseOrder: ['lines'],
	Query: [
		'activityById',
		'activityStageById',
		'addressValidate',
		'adminUserById',
		'adyenConfiguredStorisLocationById',
		'appById',
		'appRegistrationById',
		'autoApplyProtectionPlans',
		'cartOverrideAuthorizationRequestsByAuthorizerUserId',
		'cartsById',
		'collectionById',
		'customerById',
		'elavonConfiguredStorisLocationById',
		'eligibleProtection',
		'entraIdConfigurationById',
		'fiservConfiguredStorisLocationById',
		'getAvailableAuthorizersForOverride',
		'getIntegrations',
		'itemImages',
		'itemsByBarcode',
		'itemsById',
		'locationById',
		'monthlyUserLoginTotals',
		'openQuoteById',
		'openSalesOrderById',
		'opportunityById',
		'opportunityProjectTypeById',
		'opportunityStageById',
		'orderCreditCardPaymentTaskById',
		'orderSubmissionTaskById',
		'overrideAuthorizationRequestById',
		'paymentTaskById',
		'postalCodeById',
		'productCategoryById',
		'productGroupById',
		'purchaseOrdersById',
		'purchaseStatusById',
		'queryFinancePaymentPlans',
		'relationshipById',
		'salesOrderSignatureDocuments',
		'staffById',
		'tasksById',
		'userAuditLogById',
		'userGroupById',
		'usersById',
		'validateProtectionPlan',
		'vendorById',
		'workspaceRoleAuditLogById',
		'workspaceRoleById',
		'workspaceRolesGetFromWorkspace',
		'workspacesById',
	],
	QuoteFulfillment: ['lines'],
	Relationship: ['addresses', 'assignees'],
	RelationshipAddress: ['emails', 'phones'],
	SalesOrderFulfillment: ['lines'],
	SalesOrderSummaryHeader: ['orderHoldCodes'],
	SearchDuplicatesSuccess: ['matchingCustomers', 'matchingRelationships'],
	Staff: ['loginLocation'],
	StaffLocationAccess: [
		'customerPickupInventoryEntry',
		'deliveryInventoryEntry',
		'inventoryEntry',
		'inventoryQuery',
		'salesEntry',
		'salesQuery',
	],
	TaskSyncResult: ['entityIds', 'taskErrors'],
	User: ['permissions', 'workspaceRoles'],
	UserGroup: ['permissions'],
	UserShadow: ['permissions', 'workspaceRoles'],
	Workspace: ['features'],
	WorkspaceRole: ['permissions', 'users'],
	WorkspaceRoleShadow: ['permissions', 'users'],
};

export const unidentifiedTypeNames: readonly string[] = [
	'AccessTokenDirectCreateSuccess',
	'AccessTokenRefreshSuccess',
	'Address',
	'AddressFinderSettings',
	'AddressValidateResult',
	'AddressVerificationServiceSettings',
	'AdminPermission',
	'AdyenSettings',
	'AlternateTaxInterfaceTestConnectionResult',
	'ApplicationTimeoutSettings',
	'AppliedAllocatedOverridableSalesDiscount',
	'AtcDetails',
	'AtpDetails',
	'AuthenticationFailure',
	'AuthenticationSuccess',
	'AutoApplyProtectionPlansResult',
	'CXMSettings',
	'CapturePaymentResponse',
	'CaptureSignatureResponse',
	'CardAuthorizationResponse',
	'CardForcedRefundResponse',
	'CardReferencedRefundResponse',
	'CartFulfillmentDeliveryChargeDetails',
	'CartFulfillmentTotals',
	'CartLineAppliedDiscount',
	'CartLineComponentPrice',
	'CartLineComponentPriceVariance',
	'CartLinePrice',
	'CartLinePriceVariance',
	'CartLineSelectedProtection',
	'CartLineSpecialOrderConfiguration',
	'CartLineSpecialOrderOption',
	'CartMaximumFulfillments',
	'CartMoveLinesResult',
	'CartProtectionSummary',
	'CartSalesperson',
	'CartTotals',
	'CustomerAddressName',
	'CustomerAddressSetAsBillingResult',
	'CustomerFinanceAccountInfo',
	'CustomerStreetAddress',
	'Description',
	'DocumentOptions',
	'EStorisImageOptions',
	'ElavonConvergeApi',
	'ElavonSettings',
	'EligibleFinancePlansByProvider',
	'Email',
	'EmailAddress',
	'FairstoneFinancialSettings',
	'FinancePaymentPlan',
	'FinancingAccountSearchResponse',
	'FinancingAuthorizationAdjustmentResponse',
	'FinancingAuthorizationResponse',
	'FinancingAuthorizationReversalResponse',
	'FinancingCreditApplicationResponse',
	'FinancingErrorResponse',
	'FinancingSettlementResponse',
	'FiservGatewayApiConfiguration',
	'FiservSettings',
	'GatewayCustomerCardTokenDetail',
	'GatewayErrorResponse',
	'GetAndUpdateFiservTerminalsByLocationIdResult',
	'GetIntegrationResult',
	'InactivityTimeoutSettings',
	'ItemCurrentPromotion',
	'ItemDimensions',
	'ItemImagesSettings',
	'ItemLocationInventory',
	'ItemPromotion',
	'ItemSpecialOrderTemplateOptionPricing',
	'KitComponent',
	'LocationAddress',
	'MembershipRewards',
	'MembershipRewardsBalance',
	'MonthlyUserLoginTotal',
	'OpenQuoteTotals',
	'OpenSalesOrderTotals',
	'OrderCardPaymentResult',
	'OrderCashPaymentErrorResponse',
	'OrderCashPaymentSuccessResponse',
	'OrderCheckPaymentErrorResponse',
	'OrderCheckPaymentSuccessResponse',
	'PackageComponent',
	'PaymentAvsResponseData',
	'PaymentBinResponseData',
	'PaymentCardTokenInfo',
	'PaymentCscResponseData',
	'PaymentReceiptResponseData',
	'PaymentSignatureResponseData',
	'PaymentTaskCaptureSignatureResult',
	'PaymentTaskCardAuthorizationResult',
	'PaymentTaskCardForcedRefundResult',
	'PaymentTaskReadCustomerConfirmationResult',
	'PaymentTaskReadGiftCardResult',
	'Phone',
	'PointOfSaleDefaultFulfillmentStatusSettings',
	'PointOfSaleDirectShipSettings',
	'PointOfSaleSettings',
	'PointOfSaleSpecialOrderSettings',
	'ProtectionPlanFixedPriceFixedCostTableRow',
	'ProtectionPlanFixedPricePercentageCostTableRow',
	'ProtectionPlanPercentagePriceFixedCostTableRow',
	'ProtectionPlanPercentagePricePercentageCostTableRow',
	'QueryMeta',
	'QuoteSalesperson',
	'ReadCustomerConfirmationResponse',
	'ReadGiftCardResponse',
	'RelationshipAddressName',
	'RelationshipOpenSalesOrdersSummary',
	'RelationshipPhone',
	'RelationshipStreetAddress',
	'SalesOrderMinimumDeposit',
	'SalesOrderSalesperson',
	'SearchDuplicatesFailure',
	'SearchDuplicatesSuccess',
	'SellableAsIsPiece',
	'SendgridSettings',
	'Shift4CustomerCardTokenDetail',
	'SmtpSettings',
	'SourceAdmin',
	'SourceApp',
	'SourceUnauthenticated',
	'SourceUser',
	'SpecialOrderSettingsPurchaseOrderOptions',
	'StaffLocationAccess',
	'SynchronySettings',
	'TakeOrderCreditCardAuthorizationToCaptureResponse',
	'TakeOrderCreditCardPaymentWithTokenResponse',
	'TaskError',
	'TaskImportResult',
	'TaskSyncResult',
	'UserAddWorkspaceRoleError',
	'UserEntityChanged',
	'UserRemoveWorkspaceRoleError',
	'ValidatedProtectionPlan',
	'VertexSettings',
	'VoidResponse',
	'WorkspaceErpDetails',
	'WorkspaceErpServerDetails',
	'WorkspaceFeature',
	'WorkspacePermission',
	'WorkspaceRoleEntityChanged',
];
