export type Maybe<T> = T | null;
export type InputMaybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = {
	[_ in K]?: never;
};
export type Incremental<T> =
	| T
	| { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
	ID: { input: string; output: string };
	String: { input: string; output: string };
	Boolean: { input: boolean; output: boolean };
	Int: { input: number; output: number };
	Float: { input: number; output: number };
	/**
	 * A date string, such as 2007-12-03, compliant with the `full-date` format outlined in section 5.6 of the RFC 3339
	 * profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar.
	 */
	Date: { input: string; output: string };
	/**
	 * A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section
	 * 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian
	 * calendar.
	 */
	DateTime: { input: string; output: string };
	/**
	 * A date string at U2 server local time, such as 2019-07-24, compliant with the `full-date` format outlined in
	 * section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the
	 * Gregorian calendar
	 */
	ISOCalendarDate: { input: string; output: string };
	/**
	 * A date-time string at U2 server local time, such as 2019-07-24T22:15:47.789, which is not directly compliant with
	 * any format outlined in section 5.6of the RFC 3339 profile of the ISO 8601 standard for representation of dates and
	 * times using the Gregorian calendar; this is a modification of the RFC 3339 standard formatted as `full-date "T"
	 * partial-time`
	 */
	ISOCalendarDateTime: { input: string; output: string };
	/**
	 * A time string at U2 server local time, such as 22:15:47.789, compliant with the `partial-time` format outlined in
	 * section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the
	 * Gregorian calendar
	 */
	ISOTime: { input: string; output: string };
	/**
	 * A time string at UTC, such as 10:15:30Z, compliant with the `full-time` format outlined in section 5.6 of the RFC
	 * 3339profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar.
	 */
	Time: { input: string; output: string };
	/** The `Upload` scalar type represents a file upload. */
	Upload: { input: File | FileList | Blob; output: File | FileList | Blob };
	/** Represents NULL values */
	Void: { input: null; output: null };
};

/** Activity Comment Sort Enumerations */
export const ActivityCommentQuerySort = {
	/** Created Timestamp */
	createdAt_ASC: 'createdAt_ASC',
	/** Created Timestamp Descending */
	createdAt_DESC: 'createdAt_DESC',
	/** Updated Timestamp */
	updatedAt_ASC: 'updatedAt_ASC',
	/** Updated Timestamp Descending */
	updatedAt_DESC: 'updatedAt_DESC',
} as const;

export type ActivityCommentQuerySort =
	(typeof ActivityCommentQuerySort)[keyof typeof ActivityCommentQuerySort];
/** Activity Create Input */
export type ActivityCreateInput = {
	readonly dueDate: Scalars['DateTime']['input'];
	readonly ownerId: Scalars['String']['input'];
	readonly relationshipId?: InputMaybe<Scalars['String']['input']>;
	readonly stageId: Scalars['String']['input'];
	readonly subject: Scalars['String']['input'];
};

/** Activity Sort Enumerations */
export const ActivityQuerySort = {
	/** Created Timestamp */
	createdAt_ASC: 'createdAt_ASC',
	/** Created Timestamp Descending */
	createdAt_DESC: 'createdAt_DESC',
	/** DueDate */
	dueDate_ASC: 'dueDate_ASC',
	/** DueDate Descending */
	dueDate_DESC: 'dueDate_DESC',
	/** Updated Timestamp */
	updatedAt_ASC: 'updatedAt_ASC',
	/** Updated Timestamp Descending */
	updatedAt_DESC: 'updatedAt_DESC',
} as const;

export type ActivityQuerySort = (typeof ActivityQuerySort)[keyof typeof ActivityQuerySort];
/** Activity Stage Create Input */
export type ActivityStageCreateInput = {
	readonly color?: InputMaybe<Scalars['String']['input']>;
	readonly description: Scalars['String']['input'];
	readonly isActive: Scalars['Boolean']['input'];
	readonly sortOrder: Scalars['Int']['input'];
	readonly status: ActivityStageStatusType;
};

/** Activity Stage Sort Enumerations */
export const ActivityStageQuerySort = {
	/** Created Timestamp */
	createdAt_ASC: 'createdAt_ASC',
	/** Created Timestamp Descending */
	createdAt_DESC: 'createdAt_DESC',
	/** IsActive */
	isActive_ASC: 'isActive_ASC',
	/** IsActive Descending */
	isActive_DESC: 'isActive_DESC',
	/** SortOrder */
	sortOrder_ASC: 'sortOrder_ASC',
	/** SortOrder Descending */
	sortOrder_DESC: 'sortOrder_DESC',
	/** Status */
	status_ASC: 'status_ASC',
	/** Status Descending */
	status_DESC: 'status_DESC',
	/** Updated Timestamp */
	updatedAt_ASC: 'updatedAt_ASC',
	/** Updated Timestamp Descending */
	updatedAt_DESC: 'updatedAt_DESC',
} as const;

export type ActivityStageQuerySort =
	(typeof ActivityStageQuerySort)[keyof typeof ActivityStageQuerySort];
/** Activity Stage Status Enumerations */
export const ActivityStageStatusType = {
	/** Closed status */
	closed: 'closed',
	/** Open status */
	open: 'open',
} as const;

export type ActivityStageStatusType =
	(typeof ActivityStageStatusType)[keyof typeof ActivityStageStatusType];
/** Activity Stage Update Input */
export type ActivityStageUpdateInput = {
	readonly color?: InputMaybe<Scalars['String']['input']>;
	readonly description?: InputMaybe<Scalars['String']['input']>;
	readonly isActive?: InputMaybe<Scalars['Boolean']['input']>;
	readonly sortOrder?: InputMaybe<Scalars['Int']['input']>;
	readonly status?: InputMaybe<ActivityStageStatusType>;
};

/** Activity Update Input */
export type ActivityUpdateInput = {
	readonly dueDate?: InputMaybe<Scalars['DateTime']['input']>;
	readonly ownerId?: InputMaybe<Scalars['String']['input']>;
	readonly relationshipId?: InputMaybe<Scalars['String']['input']>;
	readonly stageId?: InputMaybe<Scalars['String']['input']>;
	readonly subject?: InputMaybe<Scalars['String']['input']>;
};

/** Addressfinder Settings Update Input */
export type AddressFinderSettingsUpdateInput = {
	readonly addressCleansing?: InputMaybe<Scalars['Boolean']['input']>;
	readonly licenseKey?: InputMaybe<Scalars['String']['input']>;
	readonly secret?: InputMaybe<Scalars['String']['input']>;
};

/** AddressValidateInput Entity */
export type AddressValidateInput = {
	/** ISO 3166 country code */
	readonly country?: InputMaybe<Scalars['String']['input']>;
	readonly locality?: InputMaybe<Scalars['String']['input']>;
	readonly postalCode?: InputMaybe<Scalars['String']['input']>;
	readonly region?: InputMaybe<Scalars['String']['input']>;
	readonly street1: Scalars['String']['input'];
	readonly street2?: InputMaybe<Scalars['String']['input']>;
};

/** Address Verification Service Provider Name */
export const AddressVerificationServiceProviderName = {
	addressFinder: 'addressFinder',
	avalara: 'avalara',
	none: 'none',
} as const;

export type AddressVerificationServiceProviderName =
	(typeof AddressVerificationServiceProviderName)[keyof typeof AddressVerificationServiceProviderName];
/** Address Verification Service Settings Update Input */
export type AddressVerificationServiceSettingsUpdateInput = {
	readonly addressFinderSettings?: InputMaybe<AddressFinderSettingsUpdateInput>;
	readonly providerName?: InputMaybe<AddressVerificationServiceProviderName>;
};

export const AdminPermissionName = {
	adminUserAdminister: 'adminUserAdminister',
	appRegistrationAdminister: 'appRegistrationAdminister',
	appRegistrationApiKeyCreate: 'appRegistrationApiKeyCreate',
	entraIdConfigurationAdminister: 'entraIdConfigurationAdminister',
	workspaceAdminister: 'workspaceAdminister',
	workspaceStatusUpdate: 'workspaceStatusUpdate',
} as const;

export type AdminPermissionName = (typeof AdminPermissionName)[keyof typeof AdminPermissionName];
export type AdminUserCreateInput = {
	readonly email: Scalars['String']['input'];
	readonly familyName: Scalars['String']['input'];
	readonly givenName: Scalars['String']['input'];
	readonly permissions: ReadonlyArray<Scalars['ID']['input']>;
};

export const AdminUserQuerySort = {
	email_ASC: 'email_ASC',
	email_DESC: 'email_DESC',
	familyName_ASC: 'familyName_ASC',
	familyName_DESC: 'familyName_DESC',
	givenName_ASC: 'givenName_ASC',
	givenName_DESC: 'givenName_DESC',
} as const;

export type AdminUserQuerySort = (typeof AdminUserQuerySort)[keyof typeof AdminUserQuerySort];
export type AdminUserUpdateInput = {
	readonly email?: InputMaybe<Scalars['String']['input']>;
	readonly familyName?: InputMaybe<Scalars['String']['input']>;
	readonly givenName?: InputMaybe<Scalars['String']['input']>;
	readonly permissions?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>;
};

export type AdyenConfiguredStorisLocationCreateInput = {
	readonly isActive: Scalars['Boolean']['input'];
	readonly locationId: Scalars['String']['input'];
	readonly merchantId: Scalars['String']['input'];
	readonly storeId?: InputMaybe<Scalars['String']['input']>;
};

/** Adyen Configured Storis Location Sort Enumerations */
export const AdyenConfiguredStorisLocationQuerySort = {
	/** LocationId */
	locationId_ASC: 'locationId_ASC',
	/** LocationId Descending */
	locationId_DESC: 'locationId_DESC',
} as const;

export type AdyenConfiguredStorisLocationQuerySort =
	(typeof AdyenConfiguredStorisLocationQuerySort)[keyof typeof AdyenConfiguredStorisLocationQuerySort];
export type AdyenConfiguredStorisLocationUpdateInput = {
	readonly isActive?: InputMaybe<Scalars['Boolean']['input']>;
	readonly locationId?: InputMaybe<Scalars['String']['input']>;
	readonly merchantId?: InputMaybe<Scalars['String']['input']>;
	readonly storeId?: InputMaybe<Scalars['String']['input']>;
};

export const AdyenEnvironment = { LIVE: 'LIVE', TEST: 'TEST' } as const;

export type AdyenEnvironment = (typeof AdyenEnvironment)[keyof typeof AdyenEnvironment];
/** AdyenSettings Create Input */
export type AdyenSettingsCreateInput = {
	readonly apiKey: Scalars['String']['input'];
	readonly environment: AdyenEnvironment;
	/** Required when environment is LIVE */
	readonly liveEndpointUrlPrefix?: InputMaybe<Scalars['String']['input']>;
};

export type AdyenSettingsUpdateInput = {
	readonly apiKey?: InputMaybe<Scalars['String']['input']>;
	readonly environment?: InputMaybe<AdyenEnvironment>;
	readonly liveEndpointUrlPrefix?: InputMaybe<Scalars['String']['input']>;
};

export type AppCreateInput = {
	readonly appRegistrationId: Scalars['String']['input'];
	readonly staffId: Scalars['String']['input'];
};

export const AppQuerySort = {
	createdAt_ASC: 'createdAt_ASC',
	createdAt_DESC: 'createdAt_DESC',
	lastLogin_ASC: 'lastLogin_ASC',
	lastLogin_DESC: 'lastLogin_DESC',
	staffId_ASC: 'staffId_ASC',
	staffId_DESC: 'staffId_DESC',
	updatedAt_ASC: 'updatedAt_ASC',
	updatedAt_DESC: 'updatedAt_DESC',
} as const;

export type AppQuerySort = (typeof AppQuerySort)[keyof typeof AppQuerySort];
export type AppRegistrationCreateInput = {
	readonly isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
	readonly name: Scalars['String']['input'];
	readonly permissions: ReadonlyArray<Scalars['String']['input']>;
};

export const AppRegistrationQuerySort = {
	updatedAt_ASC: 'updatedAt_ASC',
	updatedAt_DESC: 'updatedAt_DESC',
} as const;

export type AppRegistrationQuerySort =
	(typeof AppRegistrationQuerySort)[keyof typeof AppRegistrationQuerySort];
export type AppRegistrationUpdateInput = {
	readonly isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
	readonly name?: InputMaybe<Scalars['String']['input']>;
	readonly permissions?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
};

export type AppUpdateInput = { readonly staffId: Scalars['String']['input'] };

export const ApplicationKind = { joint: 'joint', single: 'single' } as const;

export type ApplicationKind = (typeof ApplicationKind)[keyof typeof ApplicationKind];
export const ApplicationStatus = {
	approved: 'approved',
	declined: 'declined',
	pending: 'pending',
} as const;

export type ApplicationStatus = (typeof ApplicationStatus)[keyof typeof ApplicationStatus];
export type ApplicationTimeoutSettingsUpdateInput = {
	/** Amount of time in seconds after which the user will be logged out if inactive. */
	readonly inactivityPeriod: Scalars['Int']['input'];
	/** Amount of time in seconds after a warning is displayed that the user will be logged out. */
	readonly warningPeriod: Scalars['Int']['input'];
};

export type ApplyCapturedOrderDepositInput = {
	readonly cardAuthorizationData: CardAuthorizationDataInput;
	readonly locationId: Scalars['ID']['input'];
	readonly orderId: Scalars['ID']['input'];
};

export type ApplyDeclinedFinancingRequestInput = {
	readonly accountId: Scalars['String']['input'];
	readonly amountRequested: Scalars['Float']['input'];
	readonly comment?: InputMaybe<Scalars['String']['input']>;
	readonly customerId: Scalars['String']['input'];
	readonly locationId: Scalars['String']['input'];
	readonly orderId: Scalars['ID']['input'];
	readonly paymentTypeId: Scalars['String']['input'];
	readonly statusMessage?: InputMaybe<Scalars['String']['input']>;
};

/** Available to Customer Statuses */
export const AtcStatus = {
	/** ATC calculation results in availability */
	available: 'available',
	/** ATC calculation resulted in an error */
	error: 'error',
	/** ATC calculation not applicable for item */
	notApplicable: 'notApplicable',
	/** ATC calculation resulted in no availability */
	notAvailable: 'notAvailable',
} as const;

export type AtcStatus = (typeof AtcStatus)[keyof typeof AtcStatus];
/** Ati ProviderName Enumerations */
export const AtiProviderName = { AVALARA: 'AVALARA', VERTEX: 'VERTEX' } as const;

export type AtiProviderName = (typeof AtiProviderName)[keyof typeof AtiProviderName];
/** Available to Promise Statuses */
export const AtpStatus = {
	/** ATP calculation results in availability */
	available: 'available',
	/** ATP calculation resulted in an error */
	error: 'error',
	/** ATP calculation not applicable for item */
	notApplicable: 'notApplicable',
	/** ATP calculation resulted in no availability */
	notAvailable: 'notAvailable',
} as const;

export type AtpStatus = (typeof AtpStatus)[keyof typeof AtpStatus];
export const AuditLogEventKind = {
	created: 'created',
	deleted: 'deleted',
	updated: 'updated',
} as const;

export type AuditLogEventKind = (typeof AuditLogEventKind)[keyof typeof AuditLogEventKind];
export type AuditLogQueryInput = {
	readonly createdAt?: InputMaybe<DateTimeComparisonInput>;
	readonly entityId?: InputMaybe<Scalars['ID']['input']>;
	readonly page?: InputMaybe<Scalars['Int']['input']>;
	readonly pageSize?: InputMaybe<Scalars['Int']['input']>;
	readonly sort?: InputMaybe<ReadonlyArray<InputMaybe<AuditLogQuerySort>>>;
};

export const AuditLogQuerySort = {
	createdAt_ASC: 'createdAt_ASC',
	createdAt_DESC: 'createdAt_DESC',
} as const;

export type AuditLogQuerySort = (typeof AuditLogQuerySort)[keyof typeof AuditLogQuerySort];
export const AuthenticationFailureReason = {
	/** The identity associated with the authentication request has been disabled */
	disabledIdentity: 'disabledIdentity',
	/** The workspace has been disabled */
	disabledWorkspace: 'disabledWorkspace',
	/**
	 * The identity associated with the authentication request does not exist, an invalid secret was provided, or a
	 * refresh token was revoked/expired
	 */
	invalidIdentityCredentials: 'invalidIdentityCredentials',
	/** The workspace does not exist */
	missingWorkspace: 'missingWorkspace',
	/** An unknown or unexpected error occurred */
	unknown: 'unknown',
	/** The workspace's ERP version is not supported by the services */
	unsupportedERPVersion: 'unsupportedERPVersion',
} as const;

export type AuthenticationFailureReason =
	(typeof AuthenticationFailureReason)[keyof typeof AuthenticationFailureReason];
/** This corresponds to a cart line in your system. */
export type AutoApplyProtectionPlansItemInput = {
	readonly itemId: Scalars['ID']['input'];
	/** Piece reference number for as-is products */
	readonly pieceReferenceNumber?: InputMaybe<Scalars['String']['input']>;
	/** The unit price this item is currently being sold for. */
	readonly price: Scalars['Float']['input'];
	readonly quantity: Scalars['Int']['input'];
	/**
	 * Unique reference for this item that will be returned in the result property appliedReferences if the item is
	 * assigned to a Protection Plan.
	 */
	readonly reference: Scalars['String']['input'];
	/**
	 * The unit price that the item normally sells for. This is needed to correctly calculate the Protection Plan price
	 * for plans that use the regular selling price of the covered items.
	 */
	readonly regularSellingPrice: Scalars['Float']['input'];
	readonly sellingLocationId: Scalars['ID']['input'];
};

/** Address Verification data if doing a card token transaction */
export type AvsDataInput = {
	readonly postalCode: Scalars['String']['input'];
	readonly streetAddress?: InputMaybe<Scalars['String']['input']>;
};

/** Address Verification Service (AVS) response codes returned by the card issuer */
export const AvsResponseCode = {
	/** Visa, Mastercard, Discover, American Express: Street address matched, postal code did not match */
	A: 'A',
	/** Visa: Street address matched, postal code in wrong format (international issuer) */
	B: 'B',
	/** Visa: Street address and postal code in wrong formats */
	C: 'C',
	/** Visa: Street address and postal code match (international issuer) */
	D: 'D',
	/** Visa: AVS data is invalid or AVS is not allowed for this card type */
	E: 'E',
	/**
	 * Visa: Street address and postal code match (UK Only) American Express: Street address matches, card member name
	 * does not match
	 */
	F: 'F',
	/** Visa: Card issued by a non-US issuer that does not participate in the AVS system */
	G: 'G',
	/** American Express: Card member name does not match, street address and postal code match */
	H: 'H',
	/** Visa: Address information not verified by international issuer */
	I: 'I',
	/** American Express: Card member name, street address, and postal code match */
	J: 'J',
	/** American Express: Card member name matches */
	K: 'K',
	/** American Express: Card member name and postal code match */
	L: 'L',
	/**
	 * Visa: Street address and postal code match (international issuer) American Express: Card member name, street
	 * address, and ZIP code match
	 */
	M: 'M',
	/** Visa, Mastercard, Discover, American Express: No match on street address or postal code */
	N: 'N',
	/** American Express: Card member name and street address match */
	O: 'O',
	/** Visa: Postal code matched, street address not verified due to incompatible formats */
	P: 'P',
	/** American Express: Card member name, street address, and postal code match */
	Q: 'Q',
	/** Visa, Mastercard, Discover, American Express: Retry, AVS System unavailable or timed out */
	R: 'R',
	/** Visa, Mastercard, Discover, American Express: Service not supported by issuer */
	S: 'S',
	/** Discover: 9-digit postal code matches, street address does not match */
	T: 'T',
	/** Visa, Mastercard, Discover, American Express: Address information is unavailable (domestic issuer) */
	U: 'U',
	/** American Express: Card member name, street address, and postal code match */
	V: 'V',
	/**
	 * Visa, Mastercard, Discover: 9-digit postal code matched, street address did not match American Express: Card member
	 * name, postal code, and street address do not match
	 */
	W: 'W',
	/** Visa, Mastercard, Discover: 9-Digit postal code and street address match */
	X: 'X',
	/** Visa, Mastercard, Discover, American Express: Street address and 5-digit postal code match */
	Y: 'Y',
	/** Visa, Mastercard, Discover, American Express: 5-digit postal code matches, street address did not match */
	Z: 'Z',
} as const;

export type AvsResponseCode = (typeof AvsResponseCode)[keyof typeof AvsResponseCode];
/** CXM Settings Update Input */
export type CXMSettingsInput = {
	readonly activitySpeedDialStageId?: InputMaybe<Scalars['String']['input']>;
	readonly allowEmailCart?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Capture Payment Request Input */
export type CapturePaymentRequestInput = {
	readonly amount?: InputMaybe<Scalars['Float']['input']>;
	readonly authorizationCode?: InputMaybe<Scalars['String']['input']>;
	/** Set to true if the transaction is from an eCommerce source */
	readonly isEcommerce?: InputMaybe<Scalars['Boolean']['input']>;
	readonly locationId: Scalars['ID']['input'];
	readonly orderId?: InputMaybe<Scalars['ID']['input']>;
	readonly originalTransactionData?: InputMaybe<OriginalTransactionDataInput>;
	readonly referenceNumber: Scalars['String']['input'];
};

/** Capture signature input */
export type CaptureSignatureInput = {
	readonly emvTerminalId: Scalars['ID']['input'];
	readonly locationId: Scalars['ID']['input'];
	readonly prompt?: InputMaybe<Scalars['String']['input']>;
};

export type CardAuthorizationDataInput = {
	readonly amountApproved: Scalars['Float']['input'];
	readonly authorizationCode?: InputMaybe<Scalars['String']['input']>;
	readonly avsData?: InputMaybe<CreditCardAvsInput>;
	readonly binData: CreditCardBinInput;
	readonly cardExpireMonth?: InputMaybe<Scalars['String']['input']>;
	readonly cardExpireYear?: InputMaybe<Scalars['String']['input']>;
	readonly cardIntegration: CreditCardGatewayProvider;
	readonly cardLastFour?: InputMaybe<Scalars['String']['input']>;
	readonly cscData?: InputMaybe<CreditCardCscInput>;
	readonly debitTransaction?: InputMaybe<Scalars['Boolean']['input']>;
	readonly referenceNumber: Scalars['String']['input'];
	readonly token?: InputMaybe<Scalars['String']['input']>;
};

/** Request schema for authorizing card payment using device */
export type CardAuthorizeAtTerminal = {
	readonly allowPartialApprovals?: InputMaybe<Scalars['Boolean']['input']>;
	readonly amount: Scalars['Float']['input'];
	readonly capture?: InputMaybe<Scalars['Boolean']['input']>;
	readonly cardWillBeStored?: InputMaybe<Scalars['Boolean']['input']>;
	readonly convenienceFeeAmount?: InputMaybe<Scalars['Float']['input']>;
	readonly customerId?: InputMaybe<Scalars['ID']['input']>;
	readonly emvTerminalId: Scalars['ID']['input'];
	readonly lineItems?: InputMaybe<ReadonlyArray<InputMaybe<PaymentLineItem>>>;
	readonly locationId: Scalars['ID']['input'];
	readonly manualEntry?: InputMaybe<Scalars['Boolean']['input']>;
	readonly orderId?: InputMaybe<Scalars['ID']['input']>;
	readonly orderTaxTotal?: InputMaybe<Scalars['Float']['input']>;
	readonly promptForCsc?: InputMaybe<Scalars['Boolean']['input']>;
	readonly promptForZip?: InputMaybe<Scalars['Boolean']['input']>;
	readonly staffId?: InputMaybe<Scalars['ID']['input']>;
};

/** Request schema for authorizing card payment using token */
export type CardAuthorizeWithToken = {
	readonly allowPartialApprovals?: InputMaybe<Scalars['Boolean']['input']>;
	readonly amount: Scalars['Float']['input'];
	readonly avsData?: InputMaybe<AvsDataInput>;
	readonly capture?: InputMaybe<Scalars['Boolean']['input']>;
	readonly card: CreditCardDataInput;
	readonly cardTokenInfo: CardTokenInfoInput;
	readonly convenienceFeeAmount?: InputMaybe<Scalars['Float']['input']>;
	readonly customerId?: InputMaybe<Scalars['ID']['input']>;
	readonly isEcommerce?: InputMaybe<Scalars['Boolean']['input']>;
	readonly lineItems?: InputMaybe<ReadonlyArray<InputMaybe<PaymentLineItem>>>;
	readonly locationId: Scalars['ID']['input'];
	readonly orderId?: InputMaybe<Scalars['ID']['input']>;
	readonly orderTaxTotal?: InputMaybe<Scalars['Float']['input']>;
	readonly staffId?: InputMaybe<Scalars['ID']['input']>;
};

/** Brand of card from transaction */
export const CardBrands = {
	/** Affirm */
	affirm: 'affirm',
	/** Afterpay */
	afterpaytouch: 'afterpaytouch',
	/** Alipay */
	alipay: 'alipay',
	/** Amazon Pay */
	amazonpay: 'amazonpay',
	americanExpress: 'americanExpress',
	/** Apple Pay */
	applepay: 'applepay',
	/** Bancontact */
	bcmc: 'bcmc',
	/** Cash App Pay */
	cashapp: 'cashapp',
	/** Clearpay | known as Afterpay in Australia, New Zealand, US, and Canada */
	clearpay: 'clearpay',
	/** China UnionPay */
	cup: 'cup',
	/** Diners Club */
	diners: 'diners',
	/** DirectEbanking (Sofort) */
	directEbanking: 'directEbanking',
	discover: 'discover',
	/** Eftpos Australia */
	eftposAustralia: 'eftposAustralia',
	/** Google Pay */
	googlepay: 'googlepay',
	/** Interac */
	interacCard: 'interacCard',
	/** JCB */
	jcb: 'jcb',
	/** Klarna pay after delivery */
	klarna: 'klarna',
	/** Klarna pay over time w/ installments */
	klarnaAccount: 'klarnaAccount',
	/** Klarna pay now */
	klarnaPaynow: 'klarnaPaynow',
	/** Maestro */
	maestro: 'maestro',
	mastercard: 'mastercard',
	nonBrandedDebit: 'nonBrandedDebit',
	/** PayPal */
	paypal: 'paypal',
	/** Samsung Pay */
	samsungpay: 'samsungpay',
	unknown: 'unknown',
	/** WeChat Pay */
	visa: 'visa',
	wechatpayQR: 'wechatpayQR',
	/** Zip */
	zip: 'zip',
} as const;

export type CardBrands = (typeof CardBrands)[keyof typeof CardBrands];
/** Forced refund at terminal input */
export type CardForcedRefundAtTerminalInput = {
	readonly amount: Scalars['Float']['input'];
	readonly emvTerminalId: Scalars['ID']['input'];
	readonly locationId: Scalars['ID']['input'];
	readonly manualEntry?: InputMaybe<Scalars['Boolean']['input']>;
	readonly promptForCsc?: InputMaybe<Scalars['Boolean']['input']>;
	readonly promptForZip?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Refund Payment with token input */
export type CardForcedRefundWithTokenInput = {
	readonly amount: Scalars['Float']['input'];
	readonly avsData?: InputMaybe<AvsDataInput>;
	readonly card: CreditCardDataInput;
	readonly cardTokenInfo: CardTokenInfoInput;
	readonly locationId: Scalars['ID']['input'];
};

/** Referenced refund input */
export type CardReferencedRefundInput = {
	readonly amount?: InputMaybe<Scalars['Float']['input']>;
	readonly locationId: Scalars['ID']['input'];
	readonly originalTransactionData?: InputMaybe<OriginalTransactionDataInput>;
	readonly referenceNumber: Scalars['String']['input'];
};

/** Information if doing a payment with a previously captured card token */
export type CardTokenInfoInput = {
	readonly scheduled: Scalars['Boolean']['input'];
	readonly source: CardTokenSource;
	readonly transactionOrigin: CardTokenTransactionOrigin;
};

/** Source of card token payment */
export const CardTokenSource = { customer: 'customer', merchant: 'merchant' } as const;

export type CardTokenSource = (typeof CardTokenSource)[keyof typeof CardTokenSource];
/** Where card token transaction took place */
export const CardTokenTransactionOrigin = {
	ecommerce: 'ecommerce',
	recurring: 'recurring',
	telephone: 'telephone',
} as const;

export type CardTokenTransactionOrigin =
	(typeof CardTokenTransactionOrigin)[keyof typeof CardTokenTransactionOrigin];
/** Indicates a card transaction's processing type */
export const CardTransactionKind = { auth: 'auth', refund: 'refund', sale: 'sale' } as const;

export type CardTransactionKind = (typeof CardTransactionKind)[keyof typeof CardTransactionKind];
/** Void payment input */
export type CardVoidPayment = {
	/** Set to true if the transaction is from an eCommerce source */
	readonly isEcommerce?: InputMaybe<Scalars['Boolean']['input']>;
	readonly locationId: Scalars['String']['input'];
	readonly originalTransactionData?: InputMaybe<OriginalTransactionDataInput>;
	readonly referenceNumber: Scalars['String']['input'];
};

/** Cart Create Input */
export type CartCreateInput = {
	readonly relationshipId?: InputMaybe<Scalars['String']['input']>;
	readonly salespeople?: InputMaybe<ReadonlyArray<CartSalespersonInput>>;
	readonly sellingLocationId: Scalars['String']['input'];
};

export const CartException = {
	/** Relationship billing address not present */
	relationshipBillingAddressMissing: 'relationshipBillingAddressMissing',
	/** Relationship not present */
	relationshipMissing: 'relationshipMissing',
} as const;

export type CartException = (typeof CartException)[keyof typeof CartException];
export type CartFulfillmentAddInput = {
	readonly locationId?: InputMaybe<Scalars['ID']['input']>;
	readonly method?: InputMaybe<CartFulfillmentMethod>;
	/**
	 * Should either pass a relationshipDeliveryAddressId or a postalCodeId. The postal code will be used to estimate
	 * totals when a full address is not provided.
	 */
	readonly postalCodeId?: InputMaybe<Scalars['String']['input']>;
	/** If specified, must correspond to one of the addresses of the relationship linked to the cart */
	readonly relationshipDeliveryAddressId?: InputMaybe<Scalars['ID']['input']>;
	readonly routeId?: InputMaybe<Scalars['ID']['input']>;
};

export type CartFulfillmentDeliveryChargeOverrideExceptionReason = {
	readonly cartFulfillmentId: Scalars['String']['input'];
	readonly deliveryChargeOverrideExceptionReason: Scalars['String']['input'];
};

export type CartFulfillmentDirectShipDetail = {
	readonly cartFulfillmentId: Scalars['ID']['input'];
	readonly fulfillmentDate: Scalars['ISOCalendarDate']['input'];
	readonly requestedDate?: InputMaybe<Scalars['ISOCalendarDate']['input']>;
};

export const CartFulfillmentException = {
	/** Deliver-to address not present (delivery only) */
	deliverToAddressMissing: 'deliverToAddressMissing',
	/** Fulfillment location not present */
	fulfillmentLocationMissing: 'fulfillmentLocationMissing',
	/** Handling method defaulting is enabled and an unauthorized user has provided a handling method override */
	handlingMethodOverride: 'handlingMethodOverride',
	/** Route not present (delivery only) */
	routeMissing: 'routeMissing',
} as const;

export type CartFulfillmentException =
	(typeof CartFulfillmentException)[keyof typeof CartFulfillmentException];
/** Cart Line Fulfillment Methods */
export const CartFulfillmentMethod = {
	customerPickup: 'customerPickup',
	delivery: 'delivery',
	directShip: 'directShip',
} as const;

export type CartFulfillmentMethod =
	(typeof CartFulfillmentMethod)[keyof typeof CartFulfillmentMethod];
export type CartFulfillmentPendingDetail = {
	readonly cartFulfillmentId: Scalars['ID']['input'];
	readonly requestedDate?: InputMaybe<Scalars['ISOCalendarDate']['input']>;
	readonly status: CartFulfillmentPendingStatus;
};

export const CartFulfillmentPendingStatus = {
	asSoonAsPossible: 'asSoonAsPossible',
	customerWillCall: 'customerWillCall',
} as const;

export type CartFulfillmentPendingStatus =
	(typeof CartFulfillmentPendingStatus)[keyof typeof CartFulfillmentPendingStatus];
export const CartFulfillmentPermissionException = {
	/** No user permission to override the delivery charge */
	deliveryChargeOverride: 'deliveryChargeOverride',
} as const;

export type CartFulfillmentPermissionException =
	(typeof CartFulfillmentPermissionException)[keyof typeof CartFulfillmentPermissionException];
export type CartFulfillmentPlannedDetail = {
	readonly cartFulfillmentId: Scalars['ID']['input'];
	readonly fulfillmentDate: Scalars['ISOCalendarDate']['input'];
	readonly requestedDate?: InputMaybe<Scalars['ISOCalendarDate']['input']>;
	readonly status: CartFulfillmentPlannedStatus;
};

export const CartFulfillmentPlannedStatus = {
	estimated: 'estimated',
	scheduled: 'scheduled',
} as const;

export type CartFulfillmentPlannedStatus =
	(typeof CartFulfillmentPlannedStatus)[keyof typeof CartFulfillmentPlannedStatus];
export type CartFulfillmentQuoteDirectShipDetail = {
	readonly cartFulfillmentId: Scalars['ID']['input'];
	readonly requestedDate?: InputMaybe<Scalars['ISOCalendarDate']['input']>;
};

export type CartFulfillmentQuotePlannedDetail = {
	readonly cartFulfillmentId: Scalars['ID']['input'];
	readonly fulfillmentDate: Scalars['ISOCalendarDate']['input'];
	readonly requestedDate?: InputMaybe<Scalars['ISOCalendarDate']['input']>;
};

/** Cart Fulfillment Update Input */
export type CartFulfillmentUpdateInput = {
	/**
	 * Overrides the calculated delivery charge amount on a fulfillment. Set to null to remove the override and return to
	 * a calculated delivery charge. This parameter is ignored for customer pickup and direct ship fulfillments.
	 */
	readonly deliveryChargeOverrideAmount?: InputMaybe<Scalars['Float']['input']>;
	/**
	 * Overrides the handling method on a fulfillment, this handling method will be used to calculate the delivery charge
	 * instead of the system determined handling method. This parameter is ignored for customer pickup and direct ship
	 * fulfillments. To override the fulfillment to have no handling method, set this property to the string
	 * 'noHandlingMethod'. Set to null to clear the handling method override and return to a system determined handling
	 * method.
	 */
	readonly handlingMethodOverrideId?: InputMaybe<Scalars['String']['input']>;
	readonly locationId?: InputMaybe<Scalars['ID']['input']>;
	readonly method?: InputMaybe<CartFulfillmentMethod>;
	/**
	 * Should either pass a relationshipDeliveryAddressId or a postalCodeId. The postal code will be used to estimate
	 * totals when a full address is not provided.
	 */
	readonly postalCodeId?: InputMaybe<Scalars['String']['input']>;
	/** If specified, must correspond to one of the addresses of the relationship linked to the cart */
	readonly relationshipDeliveryAddressId?: InputMaybe<Scalars['ID']['input']>;
	readonly routeId?: InputMaybe<Scalars['ID']['input']>;
};

/** Cart Line Add Input */
export type CartLineAddInput = {
	readonly itemId: Scalars['String']['input'];
	readonly quantityOrdered: Scalars['Int']['input'];
	readonly stockLocationId?: InputMaybe<Scalars['String']['input']>;
};

export const CartLineComponentException = {
	/** Invalid discounts */
	discountInvalid: 'discountInvalid',
	/** Discontinued items cannot be on direct ship fulfillments */
	itemDiscontinuedNotDirectShippable: 'itemDiscontinuedNotDirectShippable',
	/** Manager only protection plan unauthorized */
	managerOnlyProtectionPlanUnauthorized: 'managerOnlyProtectionPlanUnauthorized',
	/** No user permission to change the price below the minimum price threshold */
	priceBelowMinimumThreshold: 'priceBelowMinimumThreshold',
	/** Ineligible protections */
	protectionIneligible: 'protectionIneligible',
	/** Protection maximum price exceeded */
	protectionMaximumPriceExceeded: 'protectionMaximumPriceExceeded',
	/** Protection maximum quantity exceeded */
	protectionMaximumQuantityExceeded: 'protectionMaximumQuantityExceeded',
	/** Protection minimum price not met */
	protectionMinimumPriceNotMet: 'protectionMinimumPriceNotMet',
	/** Protection price not configured */
	protectionPriceNotConfigured: 'protectionPriceNotConfigured',
} as const;

export type CartLineComponentException =
	(typeof CartLineComponentException)[keyof typeof CartLineComponentException];
export const CartLineComponentPermissionException = {
	/** No user permission to apply manager only discount */
	managerOnlyDiscount: 'managerOnlyDiscount',
	/** No user permission to apply manager only protection plan */
	managerOnlyProtectionPlan: 'managerOnlyProtectionPlan',
	/** No user permission to change the price below the minimum price threshold */
	priceBelowMinimumThreshold: 'priceBelowMinimumThreshold',
	/** No user permission to checkout without a protection plan */
	protectionPlanRequired: 'protectionPlanRequired',
} as const;

export type CartLineComponentPermissionException =
	(typeof CartLineComponentPermissionException)[keyof typeof CartLineComponentPermissionException];
export const CartLineDiscountStatus = {
	invalid: 'invalid',
	unauthorized: 'unauthorized',
	valid: 'valid',
} as const;

export type CartLineDiscountStatus =
	(typeof CartLineDiscountStatus)[keyof typeof CartLineDiscountStatus];
export const CartLineException = {
	/** As-Is line with a quantity ordered greater than 1 */
	asIsQuantity: 'asIsQuantity',
	/** Invalid discounts */
	discountInvalid: 'discountInvalid',
	/** Special order template is expired */
	expiredSpecialOrderTemplate: 'expiredSpecialOrderTemplate',
	/** Special order configuration is incomplete */
	incompleteSpecialOrderConfiguration: 'incompleteSpecialOrderConfiguration',
	/** Ineligible to be converted to sales order */
	ineligibleForSalesOrder: 'ineligibleForSalesOrder',
	/** Insufficient Stock */
	insufficientStock: 'insufficientStock',
	/** Discontinued items cannot be on direct ship fulfillments */
	itemDiscontinuedNotDirectShippable: 'itemDiscontinuedNotDirectShippable',
	/** Item is purged and cannot be ordered */
	itemPurged: 'itemPurged',
	/** Manager only protection plan unauthorized */
	managerOnlyProtectionPlanUnauthorized: 'managerOnlyProtectionPlanUnauthorized',
	/** Package contains a component that has an exception */
	packageComponentException: 'packageComponentException',
	/** Package contains not configured special order components */
	packageSpecialOrderComponents: 'packageSpecialOrderComponents',
	/** Specified reference number on the cart line is no longer available to reserve */
	pieceNotAvailable: 'pieceNotAvailable',
	/** No user permission to change the price below the minimum price threshold */
	priceBelowMinimumThreshold: 'priceBelowMinimumThreshold',
	/** Price manual override is not allowed */
	priceManualOverrideNotAllowed: 'priceManualOverrideNotAllowed',
	/** Price required */
	priceRequired: 'priceRequired',
	/** Ineligible protections */
	protectionIneligible: 'protectionIneligible',
	/** Protection maximum price exceeded */
	protectionMaximumPriceExceeded: 'protectionMaximumPriceExceeded',
	/** Protection maximum quantity exceeded */
	protectionMaximumQuantityExceeded: 'protectionMaximumQuantityExceeded',
	/** Protection minimum price not met */
	protectionMinimumPriceNotMet: 'protectionMinimumPriceNotMet',
	/** Protection price not configured */
	protectionPriceNotConfigured: 'protectionPriceNotConfigured',
	/** Stock location not present */
	stockLocationMissing: 'stockLocationMissing',
} as const;

export type CartLineException = (typeof CartLineException)[keyof typeof CartLineException];
export const CartLinePermissionException = {
	/** No user permission to apply manager only discount */
	managerOnlyDiscount: 'managerOnlyDiscount',
	/** No user permission to apply manager only protection plan */
	managerOnlyProtectionPlan: 'managerOnlyProtectionPlan',
	/** Package contains a component that has an exception */
	packageComponentException: 'packageComponentException',
	/** No user permission to change the price below the minimum price threshold */
	priceBelowMinimumThreshold: 'priceBelowMinimumThreshold',
	/** No user permission to checkout without a protection plan */
	protectionPlanRequired: 'protectionPlanRequired',
} as const;

export type CartLinePermissionException =
	(typeof CartLinePermissionException)[keyof typeof CartLinePermissionException];
/** Cart Line PriceChange Alert Level */
export const CartLinePriceChangeAlertLevel = {
	none: 'none',
	reportException: 'reportException',
	securityOverrideRequired: 'securityOverrideRequired',
	warning: 'warning',
} as const;

export type CartLinePriceChangeAlertLevel =
	(typeof CartLinePriceChangeAlertLevel)[keyof typeof CartLinePriceChangeAlertLevel];
export type CartLinePriceVarianceExceptionReason = {
	readonly cartLineId: Scalars['String']['input'];
	readonly priceVarianceExceptionReason: Scalars['String']['input'];
};

export const CartLineProtectionStatus = {
	ineligible: 'ineligible',
	maximumPriceExceeded: 'maximumPriceExceeded',
	maximumQuantityExceeded: 'maximumQuantityExceeded',
	minimumPriceNotMet: 'minimumPriceNotMet',
	priceNotConfigured: 'priceNotConfigured',
	unauthorized: 'unauthorized',
	valid: 'valid',
} as const;

export type CartLineProtectionStatus =
	(typeof CartLineProtectionStatus)[keyof typeof CartLineProtectionStatus];
/** Cart Line Special Order Option Input */
export type CartLineSpecialOrderOptionInput = {
	readonly freeText?: InputMaybe<Scalars['String']['input']>;
	readonly optionId?: InputMaybe<Scalars['ID']['input']>;
	readonly optionTypeId: Scalars['ID']['input'];
	readonly price?: InputMaybe<Scalars['Float']['input']>;
	readonly templateOptionTypeId: Scalars['ID']['input'];
};

/** Cart Line Update Input */
export type CartLineUpdateInput = {
	readonly asIsReferenceNumber?: InputMaybe<Scalars['String']['input']>;
	readonly lineNotes?: InputMaybe<Scalars['String']['input']>;
	readonly price?: InputMaybe<Scalars['Float']['input']>;
	readonly quantityOrdered?: InputMaybe<Scalars['Int']['input']>;
	readonly stockLocationId?: InputMaybe<Scalars['String']['input']>;
};

/** Cart Line Update Special Order Configuration Input */
export type CartLineUpdateSpecialOrderConfigurationInput = {
	readonly options?: InputMaybe<ReadonlyArray<CartLineSpecialOrderOptionInput>>;
	readonly specialOrderDetails?: InputMaybe<Scalars['String']['input']>;
};

export const CartPermissionException = {
	/** Maximum Number of Customer Pickup Fulfillments Exceeded */
	maximumCustomerPickupFulfillmentsExceeded: 'maximumCustomerPickupFulfillmentsExceeded',
	/** Maximum Number of Delivery Fulfillments Exceeded */
	maximumDeliveryFulfillmentsExceeded: 'maximumDeliveryFulfillmentsExceeded',
} as const;

export type CartPermissionException =
	(typeof CartPermissionException)[keyof typeof CartPermissionException];
/** Cart Protection Plan Auto Add Status */
export const CartProtectionPlanAutoAddStatus = {
	/** Auto Add Protection Plans is completed */
	completed: 'completed',
	/** Auto Add Protection Plans is disabled */
	disabled: 'disabled',
	/** Auto Add Protection Plans is not required */
	notRequired: 'notRequired',
	/** Auto Add Protection Plans is required */
	required: 'required',
} as const;

export type CartProtectionPlanAutoAddStatus =
	(typeof CartProtectionPlanAutoAddStatus)[keyof typeof CartProtectionPlanAutoAddStatus];
/** Cart Sort Enumerations */
export const CartQuerySort = {
	/** Created Timestamp */
	createdAt_ASC: 'createdAt_ASC',
	/** Created Timestamp Descending */
	createdAt_DESC: 'createdAt_DESC',
	/** Updated Timestamp */
	updatedAt_ASC: 'updatedAt_ASC',
	/** Updated Timestamp Descending */
	updatedAt_DESC: 'updatedAt_DESC',
} as const;

export type CartQuerySort = (typeof CartQuerySort)[keyof typeof CartQuerySort];
/** CartSalesperson Input */
export type CartSalespersonInput = {
	readonly salespersonId: Scalars['String']['input'];
	/**
	 * If splitPercentage is provided then it must be provided for all salespeople. Percentages must total to 100 percent.
	 * Splits are ignored when creating and updating carts for now.
	 */
	readonly splitPercentage?: InputMaybe<Scalars['Float']['input']>;
};

export type CartSubmitOrderPurchaseOrderDetails = {
	readonly cartLineId: Scalars['ID']['input'];
	/** 50 character limit */
	readonly shippingInstructions?: InputMaybe<Scalars['String']['input']>;
	/**
	 * VendorModelNumber is only required for special order products. If a vendorModelNumber is not provided for a cart
	 * line for a special order product an error will be thrown.
	 */
	readonly vendorModelNumber?: InputMaybe<Scalars['String']['input']>;
	readonly vendorShipFromId: Scalars['String']['input'];
};

/** Cart Update Input */
export type CartUpdateInput = {
	readonly relationshipId?: InputMaybe<Scalars['String']['input']>;
	readonly salespeople?: InputMaybe<ReadonlyArray<CartSalespersonInput>>;
	readonly sellingLocationId?: InputMaybe<Scalars['String']['input']>;
};

/** Completed Sales Order sort enumerations */
export const CompletedSalesOrderSort = {
	/** Customer Id */
	customerId_ASC: 'customerId_ASC',
	/** Customer Id Descending */
	customerId_DESC: 'customerId_DESC',
	/** Id */
	id_ASC: 'id_ASC',
	/** Id Descending */
	id_DESC: 'id_DESC',
	/** Order Date */
	orderDate_ASC: 'orderDate_ASC',
	/** Order Date Descending */
	orderDate_DESC: 'orderDate_DESC',
} as const;

export type CompletedSalesOrderSort =
	(typeof CompletedSalesOrderSort)[keyof typeof CompletedSalesOrderSort];
export type CreateCustomerInput = {
	/** Indicates if the customer has opted in to receive solicitations. */
	readonly allowSoliciting?: InputMaybe<Scalars['Boolean']['input']>;
	/**
	 * Optionally pass in an id to use for the newly created customer. Cannot contain quote, asterisk, or backslash
	 * characters. Max number of characters is 12.
	 */
	readonly customerId?: InputMaybe<Scalars['ID']['input']>;
	/** The customer's trade type. Value must already exist in the ERP. */
	readonly customerTradeType?: InputMaybe<Scalars['String']['input']>;
	/** Delivery instructions for the customer's address. Max length is 5000 characters. */
	readonly deliveryInstructions?: InputMaybe<Scalars['String']['input']>;
	/** Customer's email addresses. Maximum number of emails is 100. */
	readonly emails?: InputMaybe<ReadonlyArray<CustomerEmailInput>>;
	/** The locality (city) of the customer's billing address. Max length is 45 characters. */
	readonly locality: Scalars['String']['input'];
	/** Customer's name. */
	readonly name: CustomerNameCreateInput;
	/** Customer's phone numbers. */
	readonly phones?: InputMaybe<ReadonlyArray<CustomerPhoneInput>>;
	/** The zip or postal code of the customer's billing address. */
	readonly postalCodeId: Scalars['String']['input'];
	/** The id of the location the customer is associated with. */
	readonly sellingStoreId: Scalars['String']['input'];
	/** The customer's selected method of statement delivery for Installment and Revolving plans. */
	readonly statementDeliveryMethod?: InputMaybe<CustomerStatementDeliveryMethod>;
	/** The first line of the street for the customer's billing address. Max length is 60 characters. */
	readonly street1: Scalars['String']['input'];
	/** The second line of the street for the customer's billing address. Max length is 60 characters. */
	readonly street2?: InputMaybe<Scalars['String']['input']>;
};

/** Create Quote Input */
export type CreateQuoteInput = {
	readonly allocatedOverridableSalesDiscount: ReadonlyArray<OrderAllocatedOverridableSalesDiscountInput>;
	readonly customerId: Scalars['String']['input'];
	readonly directShipFulfillments?: InputMaybe<ReadonlyArray<QuoteFulfillmentDirectShipInput>>;
	/**
	 * Optionally pass in an id to use for the quote id. Cannot contain quote, asterisk, or backslash characters. Max
	 * number of characters is 14.
	 */
	readonly id?: InputMaybe<Scalars['ID']['input']>;
	readonly orderComments?: InputMaybe<Scalars['String']['input']>;
	/** Order Source GUID used to track the source of the order, otherwise NextGen's will be used */
	readonly orderSource?: InputMaybe<Scalars['String']['input']>;
	readonly pendingCustomerPickupFulfillments?: InputMaybe<
		ReadonlyArray<QuoteFulfillmentPendingCustomerPickupInput>
	>;
	readonly pendingDeliveryFulfillments?: InputMaybe<
		ReadonlyArray<QuoteFulfillmentPendingDeliveryInput>
	>;
	readonly plannedCustomerPickupFulfillments?: InputMaybe<
		ReadonlyArray<QuoteFulfillmentPlannedCustomerPickupInput>
	>;
	readonly plannedDeliveryFulfillments?: InputMaybe<
		ReadonlyArray<QuoteFulfillmentPlannedDeliveryInput>
	>;
	readonly priceVarianceExceptionComment?: InputMaybe<Scalars['String']['input']>;
	readonly protectionPlans?: InputMaybe<ReadonlyArray<OrderProtectionPlanInput>>;
	readonly salesDiscountEntrySequence?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
	readonly salespeople: ReadonlyArray<OrderSalespersonInput>;
	readonly sellingLocationId: Scalars['String']['input'];
};

/** Create Sales Order Input */
export type CreateSalesOrderInput = {
	readonly allocatedOverridableSalesDiscount: ReadonlyArray<OrderAllocatedOverridableSalesDiscountInput>;
	readonly customerId: Scalars['String']['input'];
	/** Max number of characters is 20 */
	readonly customerPurchaseOrderNumber?: InputMaybe<Scalars['String']['input']>;
	readonly directShipFulfillments?: InputMaybe<ReadonlyArray<SalesOrderFulfillmentDirectShipInput>>;
	/**
	 * Optionally pass in an id to use for the order id. Cannot contain quote, asterisk, or backslash characters. Max
	 * number of characters is 14.
	 */
	readonly id?: InputMaybe<Scalars['ID']['input']>;
	readonly orderComments?: InputMaybe<Scalars['String']['input']>;
	/** Order Source GUID used to track the source of the order, otherwise NextGen's will be used */
	readonly orderSource?: InputMaybe<Scalars['String']['input']>;
	readonly pendingCustomerPickupFulfillments?: InputMaybe<
		ReadonlyArray<SalesOrderFulfillmentPendingCustomerPickupInput>
	>;
	readonly pendingDeliveryFulfillments?: InputMaybe<
		ReadonlyArray<SalesOrderFulfillmentPendingDeliveryInput>
	>;
	readonly plannedCustomerPickupFulfillments?: InputMaybe<
		ReadonlyArray<SalesOrderFulfillmentPlannedCustomerPickupInput>
	>;
	readonly plannedDeliveryFulfillments?: InputMaybe<
		ReadonlyArray<SalesOrderFulfillmentPlannedDeliveryInput>
	>;
	readonly priceVarianceExceptionComment?: InputMaybe<Scalars['String']['input']>;
	readonly protectionPlans?: InputMaybe<ReadonlyArray<OrderProtectionPlanInput>>;
	readonly salesDiscountEntrySequence?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
	readonly salespeople: ReadonlyArray<OrderSalespersonInput>;
	readonly sellingLocationId: Scalars['String']['input'];
	readonly termsAgreed?: InputMaybe<SalesOrderTermsAgreedInput>;
};

export type CreditCardAvsInput = {
	readonly avsPass: Scalars['Boolean']['input'];
	readonly avsResponse?: InputMaybe<Scalars['String']['input']>;
};

export type CreditCardBinInput = {
	readonly bin?: InputMaybe<Scalars['String']['input']>;
	readonly cardInfo?: InputMaybe<Scalars['String']['input']>;
	readonly cardType: CardBrands;
};

export type CreditCardCscInput = {
	readonly cscPass: Scalars['Boolean']['input'];
	readonly cscResponse?: InputMaybe<Scalars['String']['input']>;
};

/** Credit Card data if doing card transaction with token */
export type CreditCardDataInput = {
	readonly cardToken: Scalars['String']['input'];
	readonly expirationDate?: InputMaybe<Scalars['Date']['input']>;
	/** 3 or 4 digit card security code */
	readonly securityCode?: InputMaybe<Scalars['String']['input']>;
};

/** Integration Layer Credit Card Gateway */
export const CreditCardGatewayProvider = {
	adyen: 'adyen',
	elavon: 'elavon',
	fiserv: 'fiserv',
} as const;

export type CreditCardGatewayProvider =
	(typeof CreditCardGatewayProvider)[keyof typeof CreditCardGatewayProvider];
/** Card Verification Data (CVD) response codes returned by the card issuer */
export const CscResponseCode = {
	/** Visa, Mastercard, Discover: Match */
	M: 'M',
	/** Visa, Mastercard, Discover, American Express: No match */
	N: 'N',
	/** Visa, Mastercard, Discover: Not processed */
	P: 'P',
	/** Visa, Mastercard, Discover: Should have been present */
	S: 'S',
	/** Visa, Mastercard, Discover, American Express: Issuer unable to process */
	U: 'U',
	/** American Express: Match */
	Y: 'Y',
} as const;

export type CscResponseCode = (typeof CscResponseCode)[keyof typeof CscResponseCode];
export type CustomerAddressCreateInput = {
	/** Delivery instructions for the customer's address. Max length is 5000 characters. */
	readonly deliveryInstructions?: InputMaybe<Scalars['String']['input']>;
	/**
	 * Used to uniquely identify the address. It must be unique for the customer, meaning a customer cannot have two
	 * addresses with the same description. Max length is 30 and the following characters are not permitted: *, ', ",
	 */
	readonly description: Scalars['String']['input'];
	/** Email addresses associated with this address. */
	readonly emails?: InputMaybe<ReadonlyArray<CustomerEmailInput>>;
	/** The locality (city) of the address. Max length is 45 characters. */
	readonly locality: Scalars['String']['input'];
	/** Name of customer associated with this address */
	readonly name: CustomerNameCreateInput;
	/** Phone numbers associated with this address. */
	readonly phones?: InputMaybe<ReadonlyArray<CustomerPhoneInput>>;
	/** The zip or postal code of the address. */
	readonly postalCodeId: Scalars['String']['input'];
	/** The first line of the street address. Max length is 60 characters. */
	readonly street1: Scalars['String']['input'];
	/** The second line of the street address. Max length is 60 characters. */
	readonly street2?: InputMaybe<Scalars['String']['input']>;
};

export type CustomerAddressUpdateInput = {
	/** Delivery instructions for the customer's address. Max length is 5000 characters. */
	readonly deliveryInstructions?: InputMaybe<Scalars['String']['input']>;
	/**
	 * Used to uniquely identify the address. It must be unique for the customer, meaning a customer cannot have two
	 * addresses with the same description. Max length is 30 and the following characters are not permitted: *, ', ",
	 */
	readonly description?: InputMaybe<Scalars['String']['input']>;
	/**
	 * Email addresses associated with this address. If this property is passed in the request, it will replace all
	 * existing email addresses associated with this address. There is currently no method to update individual
	 * pre-existing email addresses.
	 */
	readonly emails?: InputMaybe<ReadonlyArray<CustomerEmailInput>>;
	/** The locality (city) of the address. Max length is 45 characters. */
	readonly locality?: InputMaybe<Scalars['String']['input']>;
	/** Name of customer associated with this address */
	readonly name?: InputMaybe<CustomerNameUpdateInput>;
	/**
	 * Phone numbers associated with this address. If this property is passed in the request, it will replace all existing
	 * phone numbers associated with this address. There is currently no method to update individual pre-existing phone
	 * numbers.
	 */
	readonly phones?: InputMaybe<ReadonlyArray<CustomerPhoneInput>>;
	/** The zip or postal code of the address. */
	readonly postalCodeId?: InputMaybe<Scalars['String']['input']>;
	/** The first line of the street address. Max length is 60 characters. */
	readonly street1?: InputMaybe<Scalars['String']['input']>;
	/** The second line of the street address. Max length is 60 characters. */
	readonly street2?: InputMaybe<Scalars['String']['input']>;
};

export type CustomerEmailInput = {
	/** Customer's email address. Max length is 50 characters. */
	readonly address: Scalars['String']['input'];
	/** Indicates if this is the primary email address for the customer. */
	readonly primary?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Customer Type Enumerations */
export const CustomerMergeStatus = {
	/** Attempted Customer Merge */
	attemptedMerge: 'attemptedMerge',
	/** Customer Merged */
	merged: 'merged',
	/** Customer Not Merged */
	notMerged: 'notMerged',
	/** Customer Pending Merge */
	pendingMerge: 'pendingMerge',
	/** Customer Recommended for Merging */
	recommendedForMerging: 'recommendedForMerging',
} as const;

export type CustomerMergeStatus = (typeof CustomerMergeStatus)[keyof typeof CustomerMergeStatus];
export type CustomerNameCreateInput = {
	/** Customer's first name. Max length is 20 characters. */
	readonly first?: InputMaybe<Scalars['String']['input']>;
	/** Customer's last name. Max length is 50 characters. */
	readonly last: Scalars['String']['input'];
	/** Customer's middle name. Max length is 15 characters. */
	readonly middle?: InputMaybe<Scalars['String']['input']>;
	/** Customer's name prefix. Max length is 5 characters. */
	readonly prefix?: InputMaybe<Scalars['String']['input']>;
	/** Customer's name suffix. Max length is 5 characters. */
	readonly suffix?: InputMaybe<Scalars['String']['input']>;
};

export type CustomerNameUpdateInput = {
	/** Customer's first name. Max length is 20 characters. */
	readonly first?: InputMaybe<Scalars['String']['input']>;
	/** Customer's last name. Max length is 50 characters. null is not permitted as this property requires a value. */
	readonly last?: InputMaybe<Scalars['String']['input']>;
	/** Customer's middle name. Max length is 15 characters. */
	readonly middle?: InputMaybe<Scalars['String']['input']>;
	/** Customer's name prefix. Max length is 5 characters. */
	readonly prefix?: InputMaybe<Scalars['String']['input']>;
	/** Customer's name suffix. Max length is 5 characters. */
	readonly suffix?: InputMaybe<Scalars['String']['input']>;
};

export type CustomerPhoneInput = {
	/**
	 * Customer's phone extension. This is only applicable when the phone type is 'work'. Numeric characters only with max
	 * length of 6.
	 */
	readonly extension?: InputMaybe<Scalars['String']['input']>;
	/** Customer's phone number. Numeric characters only with length between 2 and 16. */
	readonly number: Scalars['String']['input'];
	/** Indicates if this is the primary phone number for the customer. */
	readonly primary?: InputMaybe<Scalars['Boolean']['input']>;
	/** Indicates the type of phone number. */
	readonly type: PhoneType;
};

export const CustomerStatementDeliveryMethod = {
	electronic: 'electronic',
	electronicAndPrint: 'electronicAndPrint',
	print: 'print',
} as const;

export type CustomerStatementDeliveryMethod =
	(typeof CustomerStatementDeliveryMethod)[keyof typeof CustomerStatementDeliveryMethod];
/** Customer Type Enumerations */
export const CustomerType = {
	/** Customer Created on the Fly */
	createdOnTheFly: 'createdOnTheFly',
	/** Customer Created Through Settings Process */
	createdThroughSettingsProcess: 'createdThroughSettingsProcess',
} as const;

export type CustomerType = (typeof CustomerType)[keyof typeof CustomerType];
/** DateTime comparison input type */
export type DateTimeComparisonInput = {
	readonly eq?: InputMaybe<Scalars['DateTime']['input']>;
	readonly gt?: InputMaybe<Scalars['DateTime']['input']>;
	readonly gte?: InputMaybe<Scalars['DateTime']['input']>;
	readonly lt?: InputMaybe<Scalars['DateTime']['input']>;
	readonly lte?: InputMaybe<Scalars['DateTime']['input']>;
	readonly ne?: InputMaybe<Scalars['DateTime']['input']>;
};

export const DeliveryChargeOverrideReasonSort = {
	/** Description */
	description_ASC: 'description_ASC',
	/** Description Descending */
	description_DESC: 'description_DESC',
} as const;

export type DeliveryChargeOverrideReasonSort =
	(typeof DeliveryChargeOverrideReasonSort)[keyof typeof DeliveryChargeOverrideReasonSort];
/** Indicates whether a customer accepted conditions for a specific document. */
export type DocumentAcceptanceResult = {
	readonly accepted: Scalars['Boolean']['input'];
	readonly signatureDocumentId: Scalars['ID']['input'];
};

/** Purchase Order Sort Enumerations */
export const DocumentTypes = {
	/** SalesOrder */
	salesOrder: 'salesOrder',
} as const;

export type DocumentTypes = (typeof DocumentTypes)[keyof typeof DocumentTypes];
export type ElavonConfiguredStorisLocationCreateInput = {
	/** The Elavon Converge API is only configured when the client is using the payment portal or eSTORIS */
	readonly convergeApi?: InputMaybe<ElavonConvergeApiCreateInput>;
	readonly elavonLocation: Scalars['String']['input'];
	readonly isActive: Scalars['Boolean']['input'];
	readonly storisLocationId: Scalars['String']['input'];
	readonly terminalId: Scalars['String']['input'];
};

/** Elavon Configured Storis Location Sort Enumerations */
export const ElavonConfiguredStorisLocationQuerySort = {
	storisLocationId_ASC: 'storisLocationId_ASC',
	storisLocationId_DESC: 'storisLocationId_DESC',
} as const;

export type ElavonConfiguredStorisLocationQuerySort =
	(typeof ElavonConfiguredStorisLocationQuerySort)[keyof typeof ElavonConfiguredStorisLocationQuerySort];
export type ElavonConfiguredStorisLocationUpdateInput = {
	/** The Elavon Converge API is only configured when the client is using the payment portal or eSTORIS */
	readonly convergeApi?: InputMaybe<ElavonConvergeApiUpdateInput>;
	readonly elavonLocation?: InputMaybe<Scalars['String']['input']>;
	readonly isActive?: InputMaybe<Scalars['Boolean']['input']>;
	readonly storisLocationId?: InputMaybe<Scalars['String']['input']>;
	readonly terminalId?: InputMaybe<Scalars['String']['input']>;
};

export type ElavonConvergeApiCreateInput = {
	readonly merchantId: Scalars['String']['input'];
	readonly merchantPinCode: Scalars['String']['input'];
	readonly merchantUserId: Scalars['String']['input'];
	readonly vendorId: Scalars['String']['input'];
};

export type ElavonConvergeApiUpdateInput = {
	readonly merchantId?: InputMaybe<Scalars['String']['input']>;
	readonly merchantPinCode?: InputMaybe<Scalars['String']['input']>;
	readonly merchantUserId?: InputMaybe<Scalars['String']['input']>;
	readonly vendorId?: InputMaybe<Scalars['String']['input']>;
};

export const ElavonEnvironment = { LIVE: 'LIVE', TEST: 'TEST' } as const;

export type ElavonEnvironment = (typeof ElavonEnvironment)[keyof typeof ElavonEnvironment];
/** ElavonSettings Create Input */
export type ElavonSettingsCreateInput = {
	/**
	 * List of Address Verification System (AVS) response codes that are accepted for authorized transactions. When AVS
	 * data is included in the authorization request for card-not-present transactions, a response code indicating the AVS
	 * result is returned in the response. If the value returned is in this list, the transaction is accepted; otherwise,
	 * the transaction will be voided immediately and treated as a decline due to AVS failure.
	 */
	readonly acceptedAvsResponseCodes?: InputMaybe<ReadonlyArray<AvsResponseCode>>;
	/**
	 * List of Card Verification Data (CVD) response codes that are accepted for authorized transactions. When a card's
	 * security code is included in the authorization request for card-not-present transactions, a response code
	 * indicating the validation result is returned in the response. For authorized transactions, if the value returned by
	 * the card issuer is in this list, the transaction is accepted; otherwise, the transaction will be voided immediately
	 * and treated as a decline due to CVD failure.
	 */
	readonly acceptedCscResponseCodes?: InputMaybe<ReadonlyArray<CscResponseCode>>;
	readonly chain: Scalars['String']['input'];
	/** Determines whether requests will be routed to Elavon's test or live environment */
	readonly environment: ElavonEnvironment;
};

export type ElavonSettingsUpdateInput = {
	/**
	 * List of Address Verification System (AVS) response codes that are accepted for authorized transactions. When AVS
	 * data is included in the authorization request for card-not-present transactions, a response code indicating the AVS
	 * result is returned in the response. If the value returned is in this list, the transaction is accepted; otherwise,
	 * the transaction will be voided immediately and treated as a decline due to AVS failure.
	 */
	readonly acceptedAvsResponseCodes?: InputMaybe<ReadonlyArray<AvsResponseCode>>;
	/**
	 * List of Card Verification Data (CVD) response codes that are accepted for authorized transactions. When a card's
	 * security code is included in the authorization request for card-not-present transactions, a response code
	 * indicating the validation result is returned in the response. For authorized transactions, if the value returned by
	 * the card issuer is in this list, the transaction is accepted; otherwise, the transaction will be voided immediately
	 * and treated as a decline due to CVD failure.
	 */
	readonly acceptedCscResponseCodes?: InputMaybe<ReadonlyArray<CscResponseCode>>;
	readonly chain?: InputMaybe<Scalars['String']['input']>;
	/** Determines whether requests will be routed to Elavon's test or live environment */
	readonly environment?: InputMaybe<ElavonEnvironment>;
};

export type ElavonTerminalCreateInput = {
	readonly name: Scalars['String']['input'];
	readonly pairingCode: Scalars['String']['input'];
	readonly signatureCapable: Scalars['Boolean']['input'];
	readonly storisLocationId: Scalars['String']['input'];
};

export type ElavonTerminalUpdateInput = {
	readonly signatureCapable?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Email Address Input */
export type EmailInput = { readonly address?: InputMaybe<Scalars['String']['input']> };

/** Email Sender Kind Enumerations */
export const EmailSenderKind = {
	/** NONE */
	none: 'none',
	/** Sendgrid */
	sendgrid: 'sendgrid',
	/** SMTP */
	smtp: 'smtp',
} as const;

export type EmailSenderKind = (typeof EmailSenderKind)[keyof typeof EmailSenderKind];
export const EmploymentStatus = {
	disability: 'disability',
	employed: 'employed',
	employedPartTime: 'employedPartTime',
	homemaker: 'homemaker',
	mothersAllowance: 'mothersAllowance',
	notSelected: 'notSelected',
	other: 'other',
	retired: 'retired',
	socialAssistance: 'socialAssistance',
	studentFullTime: 'studentFullTime',
	unemployed: 'unemployed',
	workersCompensation: 'workersCompensation',
} as const;

export type EmploymentStatus = (typeof EmploymentStatus)[keyof typeof EmploymentStatus];
/** Emv Terminal Sort Enumerations */
export const EmvTerminalQuerySort = {
	/** Description */
	description_ASC: 'description_ASC',
	/** Description Descending */
	description_DESC: 'description_DESC',
} as const;

export type EmvTerminalQuerySort = (typeof EmvTerminalQuerySort)[keyof typeof EmvTerminalQuerySort];
export const EnabledPaymentMethod = {
	cash: 'cash',
	check: 'check',
	creditCard: 'creditCard',
	financing: 'financing',
} as const;

export type EnabledPaymentMethod = (typeof EnabledPaymentMethod)[keyof typeof EnabledPaymentMethod];
export type EntraIdConfigurationCreateInput = {
	readonly domain: Scalars['String']['input'];
	readonly tenantId: Scalars['String']['input'];
};

export type EntraIdConfigurationUpdateInput = {
	readonly domain?: InputMaybe<Scalars['String']['input']>;
	readonly tenantId?: InputMaybe<Scalars['String']['input']>;
};

/** Exception Reason sort enumerations */
export const ExceptionReasonSort = {
	/** Description */
	description_ASC: 'description_ASC',
	/** Description Descending */
	description_DESC: 'description_DESC',
} as const;

export type ExceptionReasonSort = (typeof ExceptionReasonSort)[keyof typeof ExceptionReasonSort];
/** FairstoneFinancialSettings Create Input */
export type FairstoneFinancialSettingsCreateInput = {
	readonly apiKey: Scalars['String']['input'];
	readonly authorizationToken: Scalars['String']['input'];
	readonly clientId: Scalars['String']['input'];
	readonly environment: FairstoneSettingsEnvironment;
	readonly paymentGatewayId: Scalars['String']['input'];
	readonly signingSecret: Scalars['String']['input'];
};

/** FairstoneFinancialSettings Update Input. null is not valid value for any input field */
export type FairstoneFinancialSettingsUpdateInput = {
	readonly apiKey?: InputMaybe<Scalars['String']['input']>;
	readonly authorizationToken?: InputMaybe<Scalars['String']['input']>;
	readonly clientId?: InputMaybe<Scalars['String']['input']>;
	readonly environment?: InputMaybe<FairstoneSettingsEnvironment>;
	readonly paymentGatewayId?: InputMaybe<Scalars['String']['input']>;
	readonly signingSecret?: InputMaybe<Scalars['String']['input']>;
};

export const FairstoneSettingsEnvironment = { LIVE: 'LIVE', TEST: 'TEST' } as const;

export type FairstoneSettingsEnvironment =
	(typeof FairstoneSettingsEnvironment)[keyof typeof FairstoneSettingsEnvironment];
export const FinancePaymentException = {
	ineligibleWithDiscount: 'ineligibleWithDiscount',
	minimumPurchaseAmountNotMet: 'minimumPurchaseAmountNotMet',
	orderLinesNotInInventoryFormation: 'orderLinesNotInInventoryFormation',
} as const;

export type FinancePaymentException =
	(typeof FinancePaymentException)[keyof typeof FinancePaymentException];
export const FinanceReceivablesProviderId = {
	concoraCredit: 'concoraCredit',
	fairstoneFinancial: 'fairstoneFinancial',
	synchrony: 'synchrony',
} as const;

export type FinanceReceivablesProviderId =
	(typeof FinanceReceivablesProviderId)[keyof typeof FinanceReceivablesProviderId];
export type FinancingAccountInfoInput = {
	readonly accountNumber?: InputMaybe<Scalars['String']['input']>;
	readonly cellPhone?: InputMaybe<Scalars['String']['input']>;
	readonly customerSsnLastFour?: InputMaybe<Scalars['String']['input']>;
	readonly dateOfBirth?: InputMaybe<Scalars['ISOCalendarDate']['input']>;
	readonly firstName?: InputMaybe<Scalars['String']['input']>;
	readonly homePhone?: InputMaybe<Scalars['String']['input']>;
	readonly lastName?: InputMaybe<Scalars['String']['input']>;
	readonly locality?: InputMaybe<Scalars['String']['input']>;
	readonly postalCode?: InputMaybe<Scalars['String']['input']>;
	readonly region?: InputMaybe<Scalars['String']['input']>;
	readonly street1?: InputMaybe<Scalars['String']['input']>;
	readonly street2?: InputMaybe<Scalars['String']['input']>;
};

export type FinancingAccountPaymentInStoreRequestInput = {
	readonly accountNumber: Scalars['String']['input'];
	readonly frProvider: FinanceReceivablesProviderId;
	readonly locationId: Scalars['String']['input'];
	readonly transactionInfo: FinancingAccountPaymentInStoreTransactionInfoInput;
};

export type FinancingAccountPaymentInStoreTransactionInfoInput = {
	readonly amount: Scalars['Float']['input'];
	readonly paymentDate: Scalars['ISOCalendarDate']['input'];
	readonly paymentType: InStoreFinancingPaymentKind;
};

export type FinancingAccountSearchInput = {
	readonly accountInfo: FinancingAccountInfoInput;
	readonly frProvider: FinanceReceivablesProviderId;
	readonly locationId: Scalars['String']['input'];
};

export type FinancingAuthorizationAdjustmentRequestInput = {
	readonly accountNumber: Scalars['String']['input'];
	readonly firstName: Scalars['String']['input'];
	readonly frProvider: FinanceReceivablesProviderId;
	readonly lastName: Scalars['String']['input'];
	readonly locationId: Scalars['String']['input'];
	readonly originalAuthorizationNumber: Scalars['String']['input'];
	readonly originalAuthorizationReferenceNumber?: InputMaybe<Scalars['String']['input']>;
	readonly transactionInfo: FinancingAuthorizationAdjustmentTransactionInfoInput;
};

export type FinancingAuthorizationAdjustmentTransactionInfoInput = {
	/** This will be the adjusted amount for the transaction */
	readonly amount: Scalars['Float']['input'];
	readonly deliveryCharge: Scalars['Float']['input'];
	readonly depositAmount: Scalars['Float']['input'];
	readonly discountTotal: Scalars['Float']['input'];
	readonly fees: Scalars['Float']['input'];
	readonly installationCharge: Scalars['Float']['input'];
	/** Flag for if this is a rent to own plan, will default to false if omitted */
	readonly isInstallmentRentToOwnPlan?: InputMaybe<Scalars['Boolean']['input']>;
	readonly lineItems?: InputMaybe<ReadonlyArray<FinancingLineItemInput>>;
	readonly merchandiseTotal: Scalars['Float']['input'];
	readonly orderTotal: Scalars['Float']['input'];
	readonly planCode: Scalars['String']['input'];
	readonly protectionTotal?: InputMaybe<Scalars['Float']['input']>;
	readonly salesTax: Scalars['Float']['input'];
	readonly storisOrderId: Scalars['String']['input'];
};

export type FinancingAuthorizationRequestInput = {
	readonly accountNumber: Scalars['String']['input'];
	readonly cellPhone?: InputMaybe<Scalars['String']['input']>;
	readonly customerBillingAddress: FinancingCustomerAddressInput;
	readonly customerId?: InputMaybe<Scalars['String']['input']>;
	readonly customerSsnLastFour?: InputMaybe<Scalars['String']['input']>;
	readonly expirationDate?: InputMaybe<Scalars['Date']['input']>;
	readonly firstName: Scalars['String']['input'];
	readonly frProvider: FinanceReceivablesProviderId;
	readonly homePhone?: InputMaybe<Scalars['String']['input']>;
	readonly lastName: Scalars['String']['input'];
	readonly locationId: Scalars['String']['input'];
	readonly securityCode?: InputMaybe<Scalars['String']['input']>;
	readonly staffId?: InputMaybe<Scalars['String']['input']>;
	readonly transactionInfo: FinancingTransactionInfoInput;
};

export type FinancingAuthorizationReversalRequestInput = {
	readonly accountNumber: Scalars['String']['input'];
	readonly customerId?: InputMaybe<Scalars['String']['input']>;
	readonly firstName: Scalars['String']['input'];
	readonly frProvider: FinanceReceivablesProviderId;
	readonly lastName: Scalars['String']['input'];
	readonly locationId: Scalars['String']['input'];
	readonly staffId?: InputMaybe<Scalars['String']['input']>;
	readonly transactionInfo: FinancingAuthorizationReversalTransactionInfoInput;
};

export const FinancingAuthorizationReversalStatus = {
	approved: 'approved',
	declined: 'declined',
} as const;

export type FinancingAuthorizationReversalStatus =
	(typeof FinancingAuthorizationReversalStatus)[keyof typeof FinancingAuthorizationReversalStatus];
export type FinancingAuthorizationReversalTransactionInfoInput = {
	readonly originalAuthorizationNumber: Scalars['String']['input'];
	readonly originalAuthorizationReferenceNumber?: InputMaybe<Scalars['String']['input']>;
	readonly originalFinanceAmount: Scalars['Float']['input'];
	readonly planCode?: InputMaybe<Scalars['String']['input']>;
	readonly reversalAmount: Scalars['Float']['input'];
	readonly storisOrderId: Scalars['String']['input'];
};

export const FinancingAuthorizationStatus = {
	approved: 'approved',
	declined: 'declined',
	preQualified: 'preQualified',
} as const;

export type FinancingAuthorizationStatus =
	(typeof FinancingAuthorizationStatus)[keyof typeof FinancingAuthorizationStatus];
export type FinancingCreditApplicationByReferenceNumberInput = {
	readonly frProvider: FinanceReceivablesProviderId;
	readonly locationId: Scalars['String']['input'];
	readonly referenceNumber: Scalars['String']['input'];
};

export type FinancingCreditApplicationInfoInput = {
	readonly cellPhone?: InputMaybe<Scalars['String']['input']>;
	readonly currentAddress: FinancingCustomerAddressInput;
	readonly currentAddressTermStart?: InputMaybe<Scalars['ISOCalendarDate']['input']>;
	readonly currentEmployer?: InputMaybe<FinancingEmploymentInfoInput>;
	readonly dateOfBirth?: InputMaybe<Scalars['ISOCalendarDate']['input']>;
	readonly driversLicense?: InputMaybe<Scalars['String']['input']>;
	readonly driversLicenseExpiration?: InputMaybe<Scalars['ISOCalendarDate']['input']>;
	readonly driversLicenseState?: InputMaybe<Scalars['String']['input']>;
	readonly emailAddress?: InputMaybe<Scalars['String']['input']>;
	readonly employmentStatus?: InputMaybe<EmploymentStatus>;
	readonly firstName: Scalars['String']['input'];
	readonly hasCheckingAccount?: InputMaybe<Scalars['Boolean']['input']>;
	readonly hasSavingsAccount?: InputMaybe<Scalars['Boolean']['input']>;
	readonly homePhone?: InputMaybe<Scalars['String']['input']>;
	readonly homeValue?: InputMaybe<Scalars['Float']['input']>;
	/** Gross income */
	readonly income?: InputMaybe<Scalars['Float']['input']>;
	readonly incomePeriod?: InputMaybe<IncomePeriod>;
	readonly lastName: Scalars['String']['input'];
	readonly maritalStatus?: InputMaybe<MaritalStatus>;
	readonly middleInitial?: InputMaybe<Scalars['String']['input']>;
	readonly monthlyHousingPayment?: InputMaybe<Scalars['Float']['input']>;
	readonly mortgageBalance?: InputMaybe<Scalars['Float']['input']>;
	readonly mothersMaidenName?: InputMaybe<Scalars['String']['input']>;
	readonly netIncome?: InputMaybe<Scalars['Float']['input']>;
	readonly previousAddress?: InputMaybe<FinancingCustomerAddressInput>;
	readonly previousAddressTermEnd?: InputMaybe<Scalars['ISOCalendarDate']['input']>;
	readonly previousAddressTermStart?: InputMaybe<Scalars['ISOCalendarDate']['input']>;
	readonly previousEmployer?: InputMaybe<FinancingEmploymentInfoInput>;
	/** References for customer */
	readonly references?: InputMaybe<ReadonlyArray<FinancingReferenceInput>>;
	readonly requestForCreditCardIssued?: InputMaybe<Scalars['Boolean']['input']>;
	readonly residenceType?: InputMaybe<ResidenceStatus>;
	readonly signedApplication?: InputMaybe<Scalars['Boolean']['input']>;
	readonly socialSecurityNumber?: InputMaybe<Scalars['String']['input']>;
};

export type FinancingCreditApplicationRequestInput = {
	readonly applicationKind: ApplicationKind;
	readonly frProvider: FinanceReceivablesProviderId;
	readonly jointApplicantInfo?: InputMaybe<FinancingCreditApplicationInfoInput>;
	readonly locationId: Scalars['String']['input'];
	readonly primaryApplicantInfo: FinancingCreditApplicationInfoInput;
	readonly purchasePrice?: InputMaybe<Scalars['Float']['input']>;
	readonly requestedAmount?: InputMaybe<Scalars['Float']['input']>;
	readonly salespersonId?: InputMaybe<Scalars['String']['input']>;
};

export type FinancingCustomerAddressInput = {
	readonly locality: Scalars['String']['input'];
	readonly postalCode: Scalars['String']['input'];
	readonly region: Scalars['String']['input'];
	readonly street1: Scalars['String']['input'];
	readonly street2?: InputMaybe<Scalars['String']['input']>;
};

export type FinancingEmploymentInfoInput = {
	readonly employmentTermEndDate?: InputMaybe<Scalars['ISOCalendarDate']['input']>;
	readonly employmentTermStartDate?: InputMaybe<Scalars['ISOCalendarDate']['input']>;
	readonly jobTitle?: InputMaybe<Scalars['String']['input']>;
	readonly name?: InputMaybe<Scalars['String']['input']>;
	readonly phone?: InputMaybe<Scalars['String']['input']>;
};

export type FinancingLineItemInput = {
	readonly category?: InputMaybe<Scalars['String']['input']>;
	readonly extendedPrice: Scalars['Float']['input'];
	readonly productDescription: Scalars['String']['input'];
	readonly productId: Scalars['String']['input'];
	readonly quantity: Scalars['Int']['input'];
	readonly unitPrice: Scalars['Float']['input'];
};

export type FinancingReferenceInput = {
	readonly currentAddress?: InputMaybe<FinancingCustomerAddressInput>;
	readonly name?: InputMaybe<Scalars['String']['input']>;
	readonly phone?: InputMaybe<Scalars['String']['input']>;
	readonly referenceType?: InputMaybe<Scalars['String']['input']>;
};

export type FinancingRefundRequestInput = {
	readonly accountNumber: Scalars['String']['input'];
	readonly frProvider: FinanceReceivablesProviderId;
	readonly locationId: Scalars['String']['input'];
	readonly staffId?: InputMaybe<Scalars['String']['input']>;
	readonly transactionInfo: FinancingRefundTransactionInfoInput;
};

export type FinancingRefundTransactionInfoInput = {
	readonly amount: Scalars['Float']['input'];
	/** OriginalAuthorizationNumber is required for fairstone */
	readonly originalAuthorizationNumber?: InputMaybe<Scalars['String']['input']>;
	readonly planCode: Scalars['String']['input'];
	readonly storisOrderId: Scalars['String']['input'];
};

export type FinancingSaleAuthorizationSettlementRequestInput = {
	readonly accountNumber: Scalars['String']['input'];
	readonly frProvider: FinanceReceivablesProviderId;
	readonly locationId: Scalars['String']['input'];
	readonly staffId?: InputMaybe<Scalars['String']['input']>;
	readonly transactionInfo: FinancingSaleAuthorizationSettlementTransactionInfoInput;
};

export type FinancingSaleAuthorizationSettlementTransactionInfoInput = {
	readonly amount: Scalars['Float']['input'];
	readonly authorizationNumber: Scalars['String']['input'];
	readonly deliveryCharge: Scalars['Float']['input'];
	readonly depositAmount: Scalars['Float']['input'];
	readonly discountTotal: Scalars['Float']['input'];
	readonly fees: Scalars['Float']['input'];
	readonly installationCharge: Scalars['Float']['input'];
	readonly isInstallmentRentToOwnPlan?: InputMaybe<Scalars['Boolean']['input']>;
	readonly lineItems?: InputMaybe<ReadonlyArray<FinancingLineItemInput>>;
	readonly merchandiseTotal: Scalars['Float']['input'];
	readonly orderTotal: Scalars['Float']['input'];
	readonly planCode: Scalars['String']['input'];
	readonly protectionTotal?: InputMaybe<Scalars['Float']['input']>;
	readonly salesTax: Scalars['Float']['input'];
	readonly storisOrderId: Scalars['String']['input'];
};

export type FinancingTransactionInfoInput = {
	readonly amount: Scalars['Float']['input'];
	readonly deliveryCharge: Scalars['Float']['input'];
	readonly depositAmount: Scalars['Float']['input'];
	readonly discountTotal: Scalars['Float']['input'];
	readonly fees: Scalars['Float']['input'];
	readonly installationCharge: Scalars['Float']['input'];
	/** Flag for if this is a rent to own plan, will default to false if omitted */
	readonly isInstallmentRentToOwnPlan?: InputMaybe<Scalars['Boolean']['input']>;
	readonly lineItems?: InputMaybe<ReadonlyArray<FinancingLineItemInput>>;
	readonly merchandiseTotal: Scalars['Float']['input'];
	readonly orderTotal: Scalars['Float']['input'];
	readonly planCode: Scalars['String']['input'];
	readonly protectionTotal?: InputMaybe<Scalars['Float']['input']>;
	readonly salesTax: Scalars['Float']['input'];
	readonly storisOrderId: Scalars['String']['input'];
};

export type FiservConfiguredStorisLocationCreateInput = {
	readonly isActive: Scalars['Boolean']['input'];
	readonly locationId: Scalars['String']['input'];
	readonly merchantId: Scalars['String']['input'];
	readonly minimumSaleAmount?: InputMaybe<Scalars['Float']['input']>;
	readonly signatureRequired?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Fiserv Configured Storis Location Sort Enumerations */
export const FiservConfiguredStorisLocationQuerySort = {
	/** LocationId */
	locationId_ASC: 'locationId_ASC',
	/** LocationId Descending */
	locationId_DESC: 'locationId_DESC',
} as const;

export type FiservConfiguredStorisLocationQuerySort =
	(typeof FiservConfiguredStorisLocationQuerySort)[keyof typeof FiservConfiguredStorisLocationQuerySort];
export type FiservConfiguredStorisLocationUpdateInput = {
	readonly isActive?: InputMaybe<Scalars['Boolean']['input']>;
	readonly locationId?: InputMaybe<Scalars['String']['input']>;
	readonly merchantId?: InputMaybe<Scalars['String']['input']>;
	readonly minimumSaleAmount?: InputMaybe<Scalars['Float']['input']>;
	readonly signatureRequired?: InputMaybe<Scalars['Boolean']['input']>;
};

export const FiservEnvironment = { LIVE: 'LIVE', TEST: 'TEST' } as const;

export type FiservEnvironment = (typeof FiservEnvironment)[keyof typeof FiservEnvironment];
export type FiservSettingsCreateGatewayApiInput = {
	readonly password: Scalars['String']['input'];
	readonly username: Scalars['String']['input'];
};

/** FiservSettings Create Input */
export type FiservSettingsCreateInput = {
	readonly environment?: InputMaybe<FiservEnvironment>;
	readonly gatewayApi: FiservSettingsCreateGatewayApiInput;
	readonly terminalApi: FiservSettingsCreateTerminalApiInput;
	readonly terminalBeepEnabled?: InputMaybe<Scalars['Boolean']['input']>;
};

export type FiservSettingsCreateTerminalApiInput = {
	readonly authorizationToken: Scalars['String']['input'];
};

export type FiservSettingsUpdateGatewayApiInput = {
	readonly password?: InputMaybe<Scalars['String']['input']>;
	readonly username?: InputMaybe<Scalars['String']['input']>;
};

export type FiservSettingsUpdateInput = {
	readonly environment?: InputMaybe<FiservEnvironment>;
	readonly gatewayApi?: InputMaybe<FiservSettingsUpdateGatewayApiInput>;
	readonly terminalApi?: InputMaybe<FiservSettingsUpdateTerminalApiInput>;
	readonly terminalBeepEnabled?: InputMaybe<Scalars['Boolean']['input']>;
};

export type FiservSettingsUpdateTerminalApiInput = {
	readonly authorizationToken?: InputMaybe<Scalars['String']['input']>;
};

export type FiservTerminalUpdateInput = {
	/** Friendly name for the terminal. Maximum length is 30 characters. */
	readonly name?: InputMaybe<Scalars['String']['input']>;
};

/** Override the system calculated delivery charge with a specific amount. */
export type FulfillmentDeliveryChargeOverrideInput = {
	readonly deliveryChargeOverrideAmount: Scalars['Float']['input'];
	/** Required when delivery charge override reason flag is set as required in Point of Sale Control settings. */
	readonly deliveryChargeOverrideReasonId?: InputMaybe<Scalars['String']['input']>;
};

export type GetEligibleFinancePlansInput = {
	readonly frProviderId: ReadonlyArray<FinanceReceivablesProviderId>;
	readonly orderId: Scalars['ID']['input'];
};

export const InStoreFinancingPaymentKind = {
	cash: 'cash',
	check: 'check',
	coupon: 'coupon',
	debit: 'debit',
	/** Electronic Funds Transfer */
	eft: 'eft',
	giftCard: 'giftCard',
	moneyOrder: 'moneyOrder',
	travelersCheck: 'travelersCheck',
	wireTransfer: 'wireTransfer',
} as const;

export type InStoreFinancingPaymentKind =
	(typeof InStoreFinancingPaymentKind)[keyof typeof InStoreFinancingPaymentKind];
export type InactivityTimeoutSettingsUpdateInput = {
	/** Setting the adminApplication settings to null will disable the inactivity period for the app. */
	readonly adminApplication?: InputMaybe<ApplicationTimeoutSettingsUpdateInput>;
	/** Setting the cxmApplication settings to null will disable the inactivity period for the app. */
	readonly cxmApplication?: InputMaybe<ApplicationTimeoutSettingsUpdateInput>;
};

export const IncomePeriod = { annual: 'annual', monthly: 'monthly' } as const;

export type IncomePeriod = (typeof IncomePeriod)[keyof typeof IncomePeriod];
export const IntegrationName = { Adyen: 'Adyen', Elavon: 'Elavon', Fiserv: 'Fiserv' } as const;

export type IntegrationName = (typeof IntegrationName)[keyof typeof IntegrationName];
/** Item Allow Direct Ship Type Enumerations */
export const ItemAllowDirectShipType = {
	allowed: 'allowed',
	notAllowed: 'notAllowed',
	required: 'required',
} as const;

export type ItemAllowDirectShipType =
	(typeof ItemAllowDirectShipType)[keyof typeof ItemAllowDirectShipType];
/** Input object for getting the available to promise details */
export type ItemGetAtpInput = {
	readonly itemId: Scalars['String']['input'];
	readonly locationId: Scalars['String']['input'];
	readonly quantity?: InputMaybe<Scalars['Int']['input']>;
};

/**
 * Item Image Options Input
 *
 * The URL is required when setting the image source to eSTORIS or an error will be thrown.
 */
export type ItemImageOptionsInput = { readonly url?: InputMaybe<Scalars['String']['input']> };

/**
 * Item Images Settings Input
 *
 * The URL must be set in the options when setting the image source to eSTORIS or an error will be thrown.
 */
export type ItemImagesSettingsInput = {
	readonly options?: InputMaybe<ItemImageOptionsInput>;
	readonly source: ItemImagesSource;
};

/** Item Images Source Enumerations */
export const ItemImagesSource = {
	/** ESTORIS */
	eStoris: 'eStoris',
	/** None */
	none: 'none',
	/** STORIS */
	storis: 'storis',
} as const;

export type ItemImagesSource = (typeof ItemImagesSource)[keyof typeof ItemImagesSource];
/** ItemLocationInventories Sort Enumerations */
export const ItemLocationInventoriesSort = {
	/** Location Id */
	locationId_ASC: 'locationId_ASC',
	/** Location Id Descending */
	locationId_DESC: 'locationId_DESC',
} as const;

export type ItemLocationInventoriesSort =
	(typeof ItemLocationInventoriesSort)[keyof typeof ItemLocationInventoriesSort];
export const ItemLocationOnDisplayAvailability = {
	/** There are no on display piece at location */
	notOnDisplayAtLocation: 'notOnDisplayAtLocation',
	/** There is at least one on display piece at location */
	onDisplayAtLocation: 'onDisplayAtLocation',
} as const;

export type ItemLocationOnDisplayAvailability =
	(typeof ItemLocationOnDisplayAvailability)[keyof typeof ItemLocationOnDisplayAvailability];
export const ItemOnDisplayStatus = {
	/** No location id was provided so cannot calculate item on display status */
	noLocationSelected: 'noLocationSelected',
	/** There are no on display piece at location */
	notOnDisplayAtLocation: 'notOnDisplayAtLocation',
	/** There is at least one on display piece at location */
	onDisplayAtLocation: 'onDisplayAtLocation',
} as const;

export type ItemOnDisplayStatus = (typeof ItemOnDisplayStatus)[keyof typeof ItemOnDisplayStatus];
/** Item Sort Enumerations */
export const ItemQuerySort = {
	/** Brand Description */
	brandDescription_ASC: 'brandDescription_ASC',
	/** Brand Description Descending */
	brandDescription_DESC: 'brandDescription_DESC',
	/** Case Selling Price */
	caseSellingPrice_ASC: 'caseSellingPrice_ASC',
	/** Case Selling Price Descending */
	caseSellingPrice_DESC: 'caseSellingPrice_DESC',
	/** Collection Description */
	collectionDescription_ASC: 'collectionDescription_ASC',
	/** Collection Description Descending */
	collectionDescription_DESC: 'collectionDescription_DESC',
	/** Item Description */
	description_ASC: 'description_ASC',
	/** Item Description Descending */
	description_DESC: 'description_DESC',
	/** Group Description */
	groupDescription_ASC: 'groupDescription_ASC',
	/** Group Description Descending */
	groupDescription_DESC: 'groupDescription_DESC',
	/** SKU */
	sku_ASC: 'sku_ASC',
	/** SKU Descending */
	sku_DESC: 'sku_DESC',
	/** Vendor Name Ascending */
	vendorName_ASC: 'vendorName_ASC',
	/** Vendor Name Descending */
	vendorName_DESC: 'vendorName_DESC',
} as const;

export type ItemQuerySort = (typeof ItemQuerySort)[keyof typeof ItemQuerySort];
/** Item Type Enumerations */
export const ItemType = { kit: 'kit', package: 'package', product: 'product' } as const;

export type ItemType = (typeof ItemType)[keyof typeof ItemType];
/** Location Sort Enumerations */
export const LocationQuerySort = {
	/** Description */
	description_ASC: 'description_ASC',
	/** Description Descending */
	description_DESC: 'description_DESC',
} as const;

export type LocationQuerySort = (typeof LocationQuerySort)[keyof typeof LocationQuerySort];
export const MaritalStatus = {
	divorced: 'divorced',
	married: 'married',
	notSpecified: 'notSpecified',
	single: 'single',
	widowed: 'widowed',
} as const;

export type MaritalStatus = (typeof MaritalStatus)[keyof typeof MaritalStatus];
export type MeUpdateInput = {
	readonly defaultLocationId?: InputMaybe<Scalars['String']['input']>;
	readonly smsPhoneNumber?: InputMaybe<Scalars['String']['input']>;
};

export const MembershipRewardsStatus = {
	active: 'active',
	canceled: 'canceled',
	expired: 'expired',
	none: 'none',
} as const;

export type MembershipRewardsStatus =
	(typeof MembershipRewardsStatus)[keyof typeof MembershipRewardsStatus];
/** MonthlyUserLoginTotal Sort Enumerations */
export const MonthlyUserLoginTotalQuerySort = {
	/** Month */
	month_ASC: 'month_ASC',
	/** Month Descending */
	month_DESC: 'month_DESC',
} as const;

export type MonthlyUserLoginTotalQuerySort =
	(typeof MonthlyUserLoginTotalQuerySort)[keyof typeof MonthlyUserLoginTotalQuerySort];
/** Open Sales Order sort enumerations */
export const OpenSalesOrderSort = {
	/** Customer Id */
	customerId_ASC: 'customerId_ASC',
	/** Customer Id Descending */
	customerId_DESC: 'customerId_DESC',
	/** Id */
	id_ASC: 'id_ASC',
	/** Id Descending */
	id_DESC: 'id_DESC',
	/** Order Date */
	orderDate_ASC: 'orderDate_ASC',
	/** Order Date Descending */
	orderDate_DESC: 'orderDate_DESC',
} as const;

export type OpenSalesOrderSort = (typeof OpenSalesOrderSort)[keyof typeof OpenSalesOrderSort];
/** Opportunity Comment Sort Enumerations */
export const OpportunityCommentQuerySort = {
	/** Created Timestamp */
	createdAt_ASC: 'createdAt_ASC',
	/** Created Timestamp Descending */
	createdAt_DESC: 'createdAt_DESC',
	/** Updated Timestamp */
	updatedAt_ASC: 'updatedAt_ASC',
	/** Updated Timestamp Descending */
	updatedAt_DESC: 'updatedAt_DESC',
} as const;

export type OpportunityCommentQuerySort =
	(typeof OpportunityCommentQuerySort)[keyof typeof OpportunityCommentQuerySort];
/** Opportunity Create Input */
export type OpportunityCreateInput = {
	readonly description?: InputMaybe<Scalars['String']['input']>;
	readonly expectedClose?: InputMaybe<Scalars['DateTime']['input']>;
	readonly expectedValue?: InputMaybe<Scalars['Float']['input']>;
	readonly isNewCustomer?: InputMaybe<Scalars['Boolean']['input']>;
	readonly isReferred?: InputMaybe<Scalars['Boolean']['input']>;
	readonly orderId?: InputMaybe<Scalars['String']['input']>;
	readonly ownerId: Scalars['String']['input'];
	readonly projectTypeId?: InputMaybe<Scalars['String']['input']>;
	readonly relationshipId?: InputMaybe<Scalars['String']['input']>;
	readonly stageId: Scalars['String']['input'];
	readonly subject: Scalars['String']['input'];
};

/** Opportunity Project Type Create Input */
export type OpportunityProjectTypeCreateInput = {
	readonly color?: InputMaybe<Scalars['String']['input']>;
	readonly description: Scalars['String']['input'];
	readonly isActive: Scalars['Boolean']['input'];
	readonly sortOrder: Scalars['Int']['input'];
};

/** Opportunity Project Type Sort Enumerations */
export const OpportunityProjectTypeQuerySort = {
	/** Created Timestamp */
	createdAt_ASC: 'createdAt_ASC',
	/** Created Timestamp Descending */
	createdAt_DESC: 'createdAt_DESC',
	/** IsActive */
	isActive_ASC: 'isActive_ASC',
	/** IsActive Descending */
	isActive_DESC: 'isActive_DESC',
	/** Sort Order */
	sortOrder_ASC: 'sortOrder_ASC',
	/** Sort Order Descending */
	sortOrder_DESC: 'sortOrder_DESC',
	/** Updated Timestamp */
	updatedAt_ASC: 'updatedAt_ASC',
	/** Updated Timestamp Descending */
	updatedAt_DESC: 'updatedAt_DESC',
} as const;

export type OpportunityProjectTypeQuerySort =
	(typeof OpportunityProjectTypeQuerySort)[keyof typeof OpportunityProjectTypeQuerySort];
/** Opportunity Project Type Update Input */
export type OpportunityProjectTypeUpdateInput = {
	readonly color?: InputMaybe<Scalars['String']['input']>;
	readonly description?: InputMaybe<Scalars['String']['input']>;
	readonly isActive?: InputMaybe<Scalars['Boolean']['input']>;
	readonly sortOrder?: InputMaybe<Scalars['Int']['input']>;
};

/** Opportunity Sort Enumerations */
export const OpportunityQuerySort = {
	/** Created Timestamp */
	createdAt_ASC: 'createdAt_ASC',
	/** Created Timestamp Descending */
	createdAt_DESC: 'createdAt_DESC',
	/** ExpectedClose */
	expectedClose_ASC: 'expectedClose_ASC',
	/** ExpectedClose Descending */
	expectedClose_DESC: 'expectedClose_DESC',
	/** ExpectedValue */
	expectedValue_ASC: 'expectedValue_ASC',
	/** ExpectedValue Descending */
	expectedValue_DESC: 'expectedValue_DESC',
	/** Updated Timestamp */
	updatedAt_ASC: 'updatedAt_ASC',
	/** Updated Timestamp Descending */
	updatedAt_DESC: 'updatedAt_DESC',
} as const;

export type OpportunityQuerySort = (typeof OpportunityQuerySort)[keyof typeof OpportunityQuerySort];
/** Opportunity Stage Create Input */
export type OpportunityStageCreateInput = {
	readonly color?: InputMaybe<Scalars['String']['input']>;
	readonly description: Scalars['String']['input'];
	readonly isActive: Scalars['Boolean']['input'];
	readonly sortOrder: Scalars['Int']['input'];
	readonly status: OpportunityStageStatusType;
};

/** Opportunity Stage Sort Enumerations */
export const OpportunityStageQuerySort = {
	/** Created Timestamp */
	createdAt_ASC: 'createdAt_ASC',
	/** Created Timestamp Descending */
	createdAt_DESC: 'createdAt_DESC',
	/** ID */
	id_ASC: 'id_ASC',
	/** ID Descending */
	id_DESC: 'id_DESC',
	/** IsActive */
	isActive_ASC: 'isActive_ASC',
	/** IsActive Descending */
	isActive_DESC: 'isActive_DESC',
	/** Sort Order Ascending */
	sortOrder_ASC: 'sortOrder_ASC',
	/** Sort Order Descending */
	sortOrder_DESC: 'sortOrder_DESC',
	/** Status */
	status_ASC: 'status_ASC',
	/** Status Descending */
	status_DESC: 'status_DESC',
	/** Updated Timestamp */
	updatedAt_ASC: 'updatedAt_ASC',
	/** Updated Timestamp Descending */
	updatedAt_DESC: 'updatedAt_DESC',
} as const;

export type OpportunityStageQuerySort =
	(typeof OpportunityStageQuerySort)[keyof typeof OpportunityStageQuerySort];
/** Opportunity Stage Status Enumerations */
export const OpportunityStageStatusType = {
	/** Closed Status */
	closed: 'closed',
	/** Open Status */
	open: 'open',
} as const;

export type OpportunityStageStatusType =
	(typeof OpportunityStageStatusType)[keyof typeof OpportunityStageStatusType];
/** Opportunity Stage Update Input */
export type OpportunityStageUpdateInput = {
	readonly color?: InputMaybe<Scalars['String']['input']>;
	readonly description?: InputMaybe<Scalars['String']['input']>;
	readonly isActive?: InputMaybe<Scalars['Boolean']['input']>;
	readonly sortOrder?: InputMaybe<Scalars['Int']['input']>;
	readonly status?: InputMaybe<OpportunityStageStatusType>;
};

/** Opportunity Update Input */
export type OpportunityUpdateInput = {
	readonly description?: InputMaybe<Scalars['String']['input']>;
	readonly expectedClose?: InputMaybe<Scalars['DateTime']['input']>;
	readonly expectedValue?: InputMaybe<Scalars['Float']['input']>;
	readonly isNewCustomer?: InputMaybe<Scalars['Boolean']['input']>;
	readonly isReferred?: InputMaybe<Scalars['Boolean']['input']>;
	readonly orderId?: InputMaybe<Scalars['String']['input']>;
	readonly ownerId?: InputMaybe<Scalars['String']['input']>;
	readonly projectTypeId?: InputMaybe<Scalars['String']['input']>;
	readonly relationshipId?: InputMaybe<Scalars['String']['input']>;
	readonly stageId?: InputMaybe<Scalars['String']['input']>;
	readonly subject?: InputMaybe<Scalars['String']['input']>;
};

/** Order Allocated Overridable Sales Discount Input */
export type OrderAllocatedOverridableSalesDiscountInput = {
	readonly overrideAmount: Scalars['Float']['input'];
	readonly salesDiscountId: Scalars['String']['input'];
};

export const OrderCashPaymentReasonForFailure = {
	recordsLocked: 'recordsLocked',
	unknownError: 'unknownError',
} as const;

export type OrderCashPaymentReasonForFailure =
	(typeof OrderCashPaymentReasonForFailure)[keyof typeof OrderCashPaymentReasonForFailure];
export const OrderCheckPaymentReasonForFailure = {
	recordsLocked: 'recordsLocked',
	unknownError: 'unknownError',
} as const;

export type OrderCheckPaymentReasonForFailure =
	(typeof OrderCheckPaymentReasonForFailure)[keyof typeof OrderCheckPaymentReasonForFailure];
export const OrderCommentQuerySort = {
	createDate_ASC: 'createDate_ASC',
	createDate_DESC: 'createDate_DESC',
} as const;

export type OrderCommentQuerySort =
	(typeof OrderCommentQuerySort)[keyof typeof OrderCommentQuerySort];
/**
 * Fields for fraud analysis required for eCommerce tokenized transactions if Credit Card Fraud Analysis module is
 * active in account and enabled at location
 */
export type OrderCreditCardPaymentFraudAnalysisInput = {
	readonly browserIpAddress: Scalars['String']['input'];
	readonly sessionId: Scalars['String']['input'];
};

export const OrderCreditCardPaymentResponse = {
	authorized: 'authorized',
	declined: 'declined',
	error: 'error',
	partialAuthorization: 'partialAuthorization',
	voiceReferral: 'voiceReferral',
} as const;

export type OrderCreditCardPaymentResponse =
	(typeof OrderCreditCardPaymentResponse)[keyof typeof OrderCreditCardPaymentResponse];
export const OrderCreditCardPaymentTaskStatus = {
	completed: 'completed',
	completedNoUpdate: 'completedNoUpdate',
	failed: 'failed',
	started: 'started',
} as const;

export type OrderCreditCardPaymentTaskStatus =
	(typeof OrderCreditCardPaymentTaskStatus)[keyof typeof OrderCreditCardPaymentTaskStatus];
export type OrderFinancingRequestInput = {
	/** AccountId retrieved from customer financeAccounts array */
	readonly accountId: Scalars['String']['input'];
	readonly amount: Scalars['Float']['input'];
	readonly comment?: InputMaybe<Scalars['String']['input']>;
	readonly customerId: Scalars['String']['input'];
	readonly expirationDate?: InputMaybe<Scalars['Date']['input']>;
	readonly locationId: Scalars['String']['input'];
	readonly orderId: Scalars['ID']['input'];
	readonly paymentTypeId: Scalars['String']['input'];
	readonly securityCode?: InputMaybe<Scalars['String']['input']>;
	/** Last four of customer's SSN. If provided should be a numeric string of exactly 4 characters */
	readonly socialSecurityNumberLastFour?: InputMaybe<Scalars['String']['input']>;
};

/** Order Hold Code Enumerations */
export const OrderHoldCode = {
	/** Credit Limit Exceeded */
	C1: 'C1',
	/** Open Balance Past Due */
	C2: 'C2',
	/** Customer Inactivity Days Exceeded */
	C3: 'C3',
	/** Manual Credit Hold On All Orders */
	C4: 'C4',
	/** Pre-Authorized Deposit */
	C5: 'C5',
	/** Linked Order On Hold With Credit Check */
	C6: 'C6',
	/** EOD Past Due Balance */
	D1: 'D1',
	/** Minimum Deposit */
	D2: 'D2',
	/** Exchanged */
	E1: 'E1',
	/** Revolving Credit Exceeds Credit Limit */
	F1: 'F1',
	/** Installment Contract */
	F2: 'F2',
	/** Financing Authorization Declined */
	F3: 'F3',
	/** Financing Pending Review */
	F4: 'F4',
	/** Driver's License Verification Required */
	F5: 'F5',
	/** ECommerce Order With Open Balance */
	I1: 'I1',
	/** Authorized Ecommerce Payment */
	I2: 'I2',
	/** Awaiting Fraud Analysis */
	I3: 'I3',
	/** Fraud Analysis Returned Potential Risk */
	I4: 'I4',
	/** Sales Order Terms Signature */
	S1: 'S1',
	/** Alternate Tax Interface */
	T1: 'T1',
} as const;

export type OrderHoldCode = (typeof OrderHoldCode)[keyof typeof OrderHoldCode];
/** Quote Line Component Input */
export type OrderProtectionPlanInput = {
	readonly protectionPlanId: Scalars['String']['input'];
	/** Protection Plan Instance Reference */
	readonly reference: Scalars['String']['input'];
};

/** Order Salesperson Input */
export type OrderSalespersonInput = {
	readonly salespersonId: Scalars['String']['input'];
	readonly splitPercentage?: InputMaybe<Scalars['Float']['input']>;
};

/** Order Submission Task Status */
export const OrderSubmissionTaskStatus = {
	/** Task is complete */
	completed: 'completed',
	/** Task failed with an error */
	failed: 'failed',
	/** Task started */
	started: 'started',
} as const;

export type OrderSubmissionTaskStatus =
	(typeof OrderSubmissionTaskStatus)[keyof typeof OrderSubmissionTaskStatus];
/** Data from the original card transaction that is needed for follow-up transactions */
export type OriginalTransactionDataInput = {
	readonly amount?: InputMaybe<Scalars['Float']['input']>;
	readonly cardAuthExternalId?: InputMaybe<Scalars['ID']['input']>;
	readonly cardExpireMonth?: InputMaybe<Scalars['String']['input']>;
	readonly cardExpireYear?: InputMaybe<Scalars['String']['input']>;
	readonly cardTransactionKind?: InputMaybe<CardTransactionKind>;
	readonly token?: InputMaybe<Scalars['String']['input']>;
};

/** OverrideAuthorizationRequest Status */
export const OverrideAuthorizationRequestStatus = {
	approved: 'approved',
	declined: 'declined',
	pending: 'pending',
} as const;

export type OverrideAuthorizationRequestStatus =
	(typeof OverrideAuthorizationRequestStatus)[keyof typeof OverrideAuthorizationRequestStatus];
/** Line item data from order */
export type PaymentLineItem = {
	readonly description?: InputMaybe<Scalars['String']['input']>;
	readonly itemId: Scalars['String']['input'];
	/** Current selling unit price, including any discounts or markups */
	readonly price: Scalars['Float']['input'];
	readonly quantity: Scalars['Int']['input'];
};

/** Indicates the current status of a PaymentTask. */
export const PaymentTaskStatus = {
	completed: 'completed',
	failed: 'failed',
	started: 'started',
} as const;

export type PaymentTaskStatus = (typeof PaymentTaskStatus)[keyof typeof PaymentTaskStatus];
/** Phone number input */
export type PhoneInput = {
	readonly extension?: InputMaybe<Scalars['String']['input']>;
	readonly number: Scalars['String']['input'];
	readonly type: PhoneType;
};

/** Phone type */
export const PhoneType = { home: 'home', mobile: 'mobile', work: 'work' } as const;

export type PhoneType = (typeof PhoneType)[keyof typeof PhoneType];
/** PointOfSale Default Fulfillment Status Enumerations */
export const PointOfSaleDefaultFulfillmentStatus = {
	/** AsSoonAsPossible */
	asSoonAsPossible: 'asSoonAsPossible',
	/** CustomerWillCall */
	customerWillCall: 'customerWillCall',
	/** Estimated */
	estimated: 'estimated',
	/** Scheduled */
	scheduled: 'scheduled',
} as const;

export type PointOfSaleDefaultFulfillmentStatus =
	(typeof PointOfSaleDefaultFulfillmentStatus)[keyof typeof PointOfSaleDefaultFulfillmentStatus];
/** Point Of Sale Default Transaction Type Enumerations */
export const PointOfSaleDefaultTransactionType = {
	/** Layaway */
	layaway: 'layaway',
	/** Quote */
	quote: 'quote',
	/** Sales Order */
	salesOrder: 'salesOrder',
} as const;

export type PointOfSaleDefaultTransactionType =
	(typeof PointOfSaleDefaultTransactionType)[keyof typeof PointOfSaleDefaultTransactionType];
/** Product Type Enumerations */
export const ProductType = {
	bulk: 'bulk',
	inventory: 'inventory',
	nonInventory: 'nonInventory',
	temporarySpecialOrder: 'temporarySpecialOrder',
} as const;

export type ProductType = (typeof ProductType)[keyof typeof ProductType];
/** Promotion Status Enumerations */
export const PromotionStatus = { active: 'active', upcoming: 'upcoming' } as const;

export type PromotionStatus = (typeof PromotionStatus)[keyof typeof PromotionStatus];
/** Item or piece for which to validate a protection plan. */
export type ProtectionPlanValidateItemInput = {
	readonly itemId: Scalars['ID']['input'];
	readonly pieceReferenceNumber?: InputMaybe<Scalars['String']['input']>;
};

/** Protection Plan validation statuses. */
export const ProtectionPlanValidationStatus = {
	ineligibleItem: 'ineligibleItem',
	maximumPriceExceeded: 'maximumPriceExceeded',
	maximumQuantityExceeded: 'maximumQuantityExceeded',
	minimumPriceNotMet: 'minimumPriceNotMet',
	valid: 'valid',
} as const;

export type ProtectionPlanValidationStatus =
	(typeof ProtectionPlanValidationStatus)[keyof typeof ProtectionPlanValidationStatus];
/** Purchase Order Item Sort Enumerations */
export const PurchaseOrderItemSort = {
	/** Create Date */
	createDate_ASC: 'createDate_ASC',
	/** Create Date Descending */
	createDate_DESC: 'createDate_DESC',
	/** Delivery Date */
	deliveryDate_ASC: 'deliveryDate_ASC',
	/** Delivery Date Descending */
	deliveryDate_DESC: 'deliveryDate_DESC',
} as const;

export type PurchaseOrderItemSort =
	(typeof PurchaseOrderItemSort)[keyof typeof PurchaseOrderItemSort];
/** Purchase Order Sort Enumerations */
export const PurchaseOrderSort = {
	/** Create Date */
	createDate_ASC: 'createDate_ASC',
	/** Create Date Descending */
	createDate_DESC: 'createDate_DESC',
	/** Delivery Date */
	deliveryDate_ASC: 'deliveryDate_ASC',
	/** Delivery Date Descending */
	deliveryDate_DESC: 'deliveryDate_DESC',
} as const;

export type PurchaseOrderSort = (typeof PurchaseOrderSort)[keyof typeof PurchaseOrderSort];
/** Purchase Order Transaction Type enumerations */
export const PurchaseOrderTransactionType = {
	/** Customer's Own Material */
	customersOwnMaterial: 'customersOwnMaterial',
	/** Direct Ship */
	directShip: 'directShip',
	/** Inventory */
	inventory: 'inventory',
	/** Miscellaneous */
	miscellaneous: 'miscellaneous',
} as const;

export type PurchaseOrderTransactionType =
	(typeof PurchaseOrderTransactionType)[keyof typeof PurchaseOrderTransactionType];
/** Purchase Status Type Enumerations */
export const PurchaseStatusType = {
	active: 'active',
	discontinued: 'discontinued',
	dropped: 'dropped',
	markedDown: 'markedDown',
	oneTimeBuy: 'oneTimeBuy',
	purged: 'purged',
} as const;

export type PurchaseStatusType = (typeof PurchaseStatusType)[keyof typeof PurchaseStatusType];
/** Quote Fulfillment Direct Ship Input */
export type QuoteFulfillmentDirectShipInput = {
	readonly customerAddressId: Scalars['String']['input'];
	readonly deliveryChargeOverride?: InputMaybe<FulfillmentDeliveryChargeOverrideInput>;
	readonly lines: ReadonlyArray<QuoteLineDirectShipInput>;
	readonly requestedDate?: InputMaybe<Scalars['ISOCalendarDate']['input']>;
};

/** Quote Fulfillment Methods */
export const QuoteFulfillmentMethod = {
	customerPickup: 'customerPickup',
	delivery: 'delivery',
	directShip: 'directShip',
} as const;

export type QuoteFulfillmentMethod =
	(typeof QuoteFulfillmentMethod)[keyof typeof QuoteFulfillmentMethod];
/** Quote Fulfillment Pending Customer Pickup Input */
export type QuoteFulfillmentPendingCustomerPickupInput = {
	readonly customerAddressId?: InputMaybe<Scalars['String']['input']>;
	readonly fulfillmentLocationId: Scalars['String']['input'];
	readonly fulfillmentStatus: QuoteFulfillmentPendingStatus;
	readonly lines: ReadonlyArray<QuoteLineInput>;
	readonly requestedDate?: InputMaybe<Scalars['ISOCalendarDate']['input']>;
};

/** Quote Fulfillment Pending Delivery Input */
export type QuoteFulfillmentPendingDeliveryInput = {
	readonly customerAddressId: Scalars['String']['input'];
	readonly deliveryChargeOverride?: InputMaybe<FulfillmentDeliveryChargeOverrideInput>;
	readonly fulfillmentLocationId?: InputMaybe<Scalars['String']['input']>;
	readonly fulfillmentStatus: QuoteFulfillmentPendingStatus;
	readonly handlingMethodOverrideId?: InputMaybe<Scalars['String']['input']>;
	readonly lines: ReadonlyArray<QuoteLineInput>;
	readonly requestedDate?: InputMaybe<Scalars['ISOCalendarDate']['input']>;
	readonly routeId: Scalars['String']['input'];
};

/** Quote Fulfillment Pending Statuses */
export const QuoteFulfillmentPendingStatus = {
	asSoonAsPossible: 'asSoonAsPossible',
	customerWillCall: 'customerWillCall',
} as const;

export type QuoteFulfillmentPendingStatus =
	(typeof QuoteFulfillmentPendingStatus)[keyof typeof QuoteFulfillmentPendingStatus];
/** Quote Fulfillment Planned Customer Pickup Input */
export type QuoteFulfillmentPlannedCustomerPickupInput = {
	readonly customerAddressId?: InputMaybe<Scalars['String']['input']>;
	readonly fulfillmentDate: Scalars['ISOCalendarDate']['input'];
	readonly fulfillmentLocationId: Scalars['String']['input'];
	readonly lines: ReadonlyArray<QuoteLineInput>;
	readonly requestedDate?: InputMaybe<Scalars['ISOCalendarDate']['input']>;
};

/** Quote Fulfillment Planned Delivery Input */
export type QuoteFulfillmentPlannedDeliveryInput = {
	readonly customerAddressId: Scalars['String']['input'];
	readonly deliveryChargeOverride?: InputMaybe<FulfillmentDeliveryChargeOverrideInput>;
	readonly fulfillmentDate: Scalars['ISOCalendarDate']['input'];
	readonly fulfillmentLocationId?: InputMaybe<Scalars['String']['input']>;
	readonly handlingMethodOverrideId?: InputMaybe<Scalars['String']['input']>;
	readonly lines: ReadonlyArray<QuoteLineInput>;
	readonly requestedDate?: InputMaybe<Scalars['ISOCalendarDate']['input']>;
	readonly routeId: Scalars['String']['input'];
};

/** Quote Fulfillment Statuses */
export const QuoteFulfillmentStatus = {
	asSoonAsPossible: 'asSoonAsPossible',
	customerWillCall: 'customerWillCall',
	estimated: 'estimated',
} as const;

export type QuoteFulfillmentStatus =
	(typeof QuoteFulfillmentStatus)[keyof typeof QuoteFulfillmentStatus];
/** Quote Line Component Input */
export type QuoteLineComponentInput = {
	readonly itemId: Scalars['String']['input'];
	readonly quantity: Scalars['Int']['input'];
	readonly salesDiscounts?: InputMaybe<ReadonlyArray<QuoteLineSalesDiscountInput>>;
};

/** Quote Line Direct Ship Input */
export type QuoteLineDirectShipInput = {
	readonly comments?: InputMaybe<Scalars['String']['input']>;
	readonly components?: InputMaybe<ReadonlyArray<QuoteLineComponentInput>>;
	readonly externalIdentifier?: InputMaybe<Scalars['String']['input']>;
	readonly itemId: Scalars['String']['input'];
	readonly price?: InputMaybe<Scalars['Float']['input']>;
	readonly priceVarianceExceptionReasonId?: InputMaybe<Scalars['String']['input']>;
	/** Supports kits, packages, stock, and special order products */
	readonly purchaseOrderCreationDetails: QuoteLinePurchaseOrderCreationDetailsInput;
	readonly quantityOrdered: Scalars['Int']['input'];
	readonly reference?: InputMaybe<Scalars['String']['input']>;
	readonly salesDiscounts?: InputMaybe<ReadonlyArray<QuoteLineSalesDiscountInput>>;
	readonly specialOrderConfiguration?: InputMaybe<QuoteLineSpecialOrderConfigurationInput>;
	readonly warranties?: InputMaybe<ReadonlyArray<QuoteLineProtectionInput>>;
};

/** Quote Line Input */
export type QuoteLineInput = {
	readonly asIsReferenceNumber?: InputMaybe<Scalars['String']['input']>;
	readonly comments?: InputMaybe<Scalars['String']['input']>;
	readonly components?: InputMaybe<ReadonlyArray<QuoteLineComponentInput>>;
	readonly externalIdentifier?: InputMaybe<Scalars['String']['input']>;
	readonly itemId: Scalars['String']['input'];
	readonly price?: InputMaybe<Scalars['Float']['input']>;
	readonly priceVarianceExceptionReasonId?: InputMaybe<Scalars['String']['input']>;
	/** Required for special orders */
	readonly purchaseOrderCreationDetails?: InputMaybe<QuoteLinePurchaseOrderCreationDetailsInput>;
	readonly quantityOrdered: Scalars['Int']['input'];
	readonly reference?: InputMaybe<Scalars['String']['input']>;
	readonly salesDiscounts?: InputMaybe<ReadonlyArray<QuoteLineSalesDiscountInput>>;
	readonly specialOrderConfiguration?: InputMaybe<QuoteLineSpecialOrderConfigurationInput>;
	readonly stockLocationId?: InputMaybe<Scalars['String']['input']>;
	readonly warranties?: InputMaybe<ReadonlyArray<QuoteLineProtectionInput>>;
};

/** Quote Line Protection Input */
export type QuoteLineProtectionInput = { readonly protectionId: Scalars['String']['input'] };

/** Quote Line Purchase Order Creation Details Input */
export type QuoteLinePurchaseOrderCreationDetailsInput = {
	readonly shippingInstructions?: InputMaybe<Scalars['String']['input']>;
	readonly vendorModelNumber?: InputMaybe<Scalars['String']['input']>;
	readonly vendorShipFromId: Scalars['String']['input'];
};

/** Quote Line Sales Discount Input */
export type QuoteLineSalesDiscountInput = {
	readonly overrideAmount?: InputMaybe<Scalars['Float']['input']>;
	readonly salesDiscountId: Scalars['String']['input'];
};

/** Quote Line Special Order Configuration Input */
export type QuoteLineSpecialOrderConfigurationInput = {
	readonly options: ReadonlyArray<QuoteLineSpecialOrderConfigurationOptionInput>;
	readonly specialOrderDetails?: InputMaybe<Scalars['String']['input']>;
	readonly templateId: Scalars['String']['input'];
};

/** Quote Line Special Order Configuration Option Input */
export type QuoteLineSpecialOrderConfigurationOptionInput = {
	readonly freeText?: InputMaybe<Scalars['String']['input']>;
	readonly optionId?: InputMaybe<Scalars['String']['input']>;
	readonly optionTypeId: Scalars['String']['input'];
	readonly price?: InputMaybe<Scalars['Float']['input']>;
	readonly templateOptionTypeId: Scalars['String']['input'];
};

/** Read customer confirmation input */
export type ReadCustomerConfirmationInput = {
	readonly additionalText?: InputMaybe<Scalars['String']['input']>;
	readonly emvTerminalId: Scalars['ID']['input'];
	readonly locationId: Scalars['ID']['input'];
	readonly prompt: Scalars['String']['input'];
};

/** Read gift card input */
export type ReadGiftCardInput = {
	readonly emvTerminalId: Scalars['ID']['input'];
	readonly locationId: Scalars['ID']['input'];
};

/** Region Sort Enumerations */
export const RegionQuerySort = {
	/** FullName */
	fullName_ASC: 'fullName_ASC',
	/** FullName Descending */
	fullName_DESC: 'fullName_DESC',
	/** Region ID */
	id_ASC: 'id_ASC',
	/** Region Descending */
	id_DESC: 'id_DESC',
} as const;

export type RegionQuerySort = (typeof RegionQuerySort)[keyof typeof RegionQuerySort];
export type RelationshipAddressCreateInput = {
	readonly deliveryInstructions?: InputMaybe<Scalars['String']['input']>;
	readonly description: Scalars['String']['input'];
	readonly emails?: InputMaybe<ReadonlyArray<RelationshipAddressEmailInput>>;
	readonly isBilling?: InputMaybe<Scalars['Boolean']['input']>;
	readonly isPrimaryDelivery?: InputMaybe<Scalars['Boolean']['input']>;
	readonly name: RelationshipAddressNameInput;
	readonly phones?: InputMaybe<ReadonlyArray<RelationshipAddressPhoneInput>>;
	readonly streetAddress: RelationshipAddressStreetAddressInput;
};

export type RelationshipAddressEmailInput = {
	readonly address: Scalars['String']['input'];
	readonly primary?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Total Max Length: 50 characters */
export type RelationshipAddressNameInput = {
	/** Max Length: 20 characters */
	readonly first?: InputMaybe<Scalars['String']['input']>;
	/** Max Length: 50 characters */
	readonly last: Scalars['String']['input'];
	/** Max Length: 15 characters */
	readonly middle?: InputMaybe<Scalars['String']['input']>;
	/** Max Length: 5 characters */
	readonly prefix?: InputMaybe<Scalars['String']['input']>;
	/** Max Length: 5 characters */
	readonly suffix?: InputMaybe<Scalars['String']['input']>;
};

/** Total Max Length: 50 characters */
export type RelationshipAddressNameUpdateInput = {
	/** Max Length: 20 characters */
	readonly first?: InputMaybe<Scalars['String']['input']>;
	/** Max Length: 50 characters */
	readonly last?: InputMaybe<Scalars['String']['input']>;
	/** Max Length: 15 characters */
	readonly middle?: InputMaybe<Scalars['String']['input']>;
	/** Max Length: 5 characters */
	readonly prefix?: InputMaybe<Scalars['String']['input']>;
	/** Max Length: 5 characters */
	readonly suffix?: InputMaybe<Scalars['String']['input']>;
};

export type RelationshipAddressPhoneInput = {
	readonly extension?: InputMaybe<Scalars['String']['input']>;
	readonly number: Scalars['String']['input'];
	readonly primary?: InputMaybe<Scalars['Boolean']['input']>;
	readonly type: PhoneType;
};

export type RelationshipAddressStreetAddressInput = {
	/** The locality (city) of the address. Max length is 45 characters. */
	readonly locality: Scalars['String']['input'];
	/** The zip or postal code of the address. */
	readonly postalCodeId: Scalars['String']['input'];
	/** The first line of the street address. Max length is 60 characters. */
	readonly street1: Scalars['String']['input'];
	/** The second line of the street address. Max length is 60 characters. */
	readonly street2?: InputMaybe<Scalars['String']['input']>;
};

export type RelationshipAddressStreetAddressUpdateInput = {
	/** The locality (city) of the address. Max length is 45 characters. */
	readonly locality?: InputMaybe<Scalars['String']['input']>;
	/** The zip or postal code of the address. */
	readonly postalCodeId?: InputMaybe<Scalars['String']['input']>;
	/** The first line of the street address. Max length is 60 characters. */
	readonly street1?: InputMaybe<Scalars['String']['input']>;
	/** The second line of the street address. Max length is 60 characters. */
	readonly street2?: InputMaybe<Scalars['String']['input']>;
};

export type RelationshipAddressUpdateInput = {
	readonly deliveryInstructions?: InputMaybe<Scalars['String']['input']>;
	readonly description?: InputMaybe<Scalars['String']['input']>;
	readonly emails?: InputMaybe<ReadonlyArray<RelationshipAddressEmailInput>>;
	readonly name?: InputMaybe<RelationshipAddressNameUpdateInput>;
	readonly phones?: InputMaybe<ReadonlyArray<RelationshipAddressPhoneInput>>;
	readonly streetAddress?: InputMaybe<RelationshipAddressStreetAddressUpdateInput>;
};

/** Relationship Assignee Input */
export type RelationshipAssigneeInput = { readonly userId: Scalars['ID']['input'] };

/** Relationship Create Input */
export type RelationshipCreateInput = {
	readonly contactEmail?: InputMaybe<Scalars['String']['input']>;
	readonly contactPhone?: InputMaybe<PhoneInput>;
	readonly description?: InputMaybe<Scalars['String']['input']>;
	readonly name: Scalars['String']['input'];
};

/** Relationship Sort Enumerations */
export const RelationshipQuerySort = {
	/** Created Timestamp */
	createdAt_ASC: 'createdAt_ASC',
	/** Created Timestamp Descending */
	createdAt_DESC: 'createdAt_DESC',
	/** LastActivity */
	lastActivity_ASC: 'lastActivity_ASC',
	/** LastActivity Descending */
	lastActivity_DESC: 'lastActivity_DESC',
	/** Name */
	name_ASC: 'name_ASC',
	/** Name Descending */
	name_DESC: 'name_DESC',
	/** Updated Timestamp */
	updatedAt_ASC: 'updatedAt_ASC',
	/** Updated Timestamp Descending */
	updatedAt_DESC: 'updatedAt_DESC',
} as const;

export type RelationshipQuerySort =
	(typeof RelationshipQuerySort)[keyof typeof RelationshipQuerySort];
/** Relationship Update Input */
export type RelationshipUpdateInput = {
	readonly contactEmail?: InputMaybe<Scalars['String']['input']>;
	readonly contactPhone?: InputMaybe<PhoneInput>;
	readonly description?: InputMaybe<Scalars['String']['input']>;
	readonly name?: InputMaybe<Scalars['String']['input']>;
};

export const ResidenceStatus = {
	notSelected: 'notSelected',
	other: 'other',
	own: 'own',
	rental: 'rental',
} as const;

export type ResidenceStatus = (typeof ResidenceStatus)[keyof typeof ResidenceStatus];
/** Sales Discount Enumerations */
export const SalesDiscountType = { amount: 'amount', percent: 'percent' } as const;

export type SalesDiscountType = (typeof SalesDiscountType)[keyof typeof SalesDiscountType];
/** Sales Order Fulfillment Direct Ship Input */
export type SalesOrderFulfillmentDirectShipInput = {
	readonly customerAddressId: Scalars['String']['input'];
	readonly deliveryChargeOverride?: InputMaybe<FulfillmentDeliveryChargeOverrideInput>;
	readonly fulfillmentDate: Scalars['ISOCalendarDate']['input'];
	readonly lines: ReadonlyArray<SalesOrderLineDirectShipInput>;
	readonly requestedDate?: InputMaybe<Scalars['ISOCalendarDate']['input']>;
};

/** Sales Order Fulfillment Methods */
export const SalesOrderFulfillmentMethod = {
	customerPickup: 'customerPickup',
	delivery: 'delivery',
	directShip: 'directShip',
	takeWith: 'takeWith',
} as const;

export type SalesOrderFulfillmentMethod =
	(typeof SalesOrderFulfillmentMethod)[keyof typeof SalesOrderFulfillmentMethod];
/** Sales Order Fulfillment Pending Customer Pickup Input */
export type SalesOrderFulfillmentPendingCustomerPickupInput = {
	readonly customerAddressId?: InputMaybe<Scalars['String']['input']>;
	readonly fulfillmentLocationId: Scalars['String']['input'];
	readonly fulfillmentStatus: SalesOrderFulfillmentPendingStatus;
	readonly lines: ReadonlyArray<SalesOrderLineInput>;
	readonly requestedDate?: InputMaybe<Scalars['ISOCalendarDate']['input']>;
};

/** Sales Order Fulfillment Pending Delivery Input */
export type SalesOrderFulfillmentPendingDeliveryInput = {
	readonly customerAddressId: Scalars['String']['input'];
	readonly deliveryChargeOverride?: InputMaybe<FulfillmentDeliveryChargeOverrideInput>;
	readonly fulfillmentLocationId?: InputMaybe<Scalars['String']['input']>;
	readonly fulfillmentStatus: SalesOrderFulfillmentPendingStatus;
	readonly handlingMethodOverrideId?: InputMaybe<Scalars['String']['input']>;
	readonly lines: ReadonlyArray<SalesOrderLineInput>;
	readonly requestedDate?: InputMaybe<Scalars['ISOCalendarDate']['input']>;
	readonly routeId: Scalars['String']['input'];
};

/** Sales Order Fulfillment Pending Statuses */
export const SalesOrderFulfillmentPendingStatus = {
	asSoonAsPossible: 'asSoonAsPossible',
	customerWillCall: 'customerWillCall',
} as const;

export type SalesOrderFulfillmentPendingStatus =
	(typeof SalesOrderFulfillmentPendingStatus)[keyof typeof SalesOrderFulfillmentPendingStatus];
/** Sales Order Fulfillment Planned Customer Pickup Input */
export type SalesOrderFulfillmentPlannedCustomerPickupInput = {
	readonly customerAddressId?: InputMaybe<Scalars['String']['input']>;
	readonly fulfillmentDate: Scalars['ISOCalendarDate']['input'];
	readonly fulfillmentLocationId: Scalars['String']['input'];
	readonly fulfillmentStatus: SalesOrderFulfillmentPlannedStatus;
	readonly lines: ReadonlyArray<SalesOrderLineInput>;
	readonly requestedDate?: InputMaybe<Scalars['ISOCalendarDate']['input']>;
};

/** Sales Order Fulfillment Planned Delivery Input */
export type SalesOrderFulfillmentPlannedDeliveryInput = {
	readonly customerAddressId: Scalars['String']['input'];
	readonly deliveryChargeOverride?: InputMaybe<FulfillmentDeliveryChargeOverrideInput>;
	readonly fulfillmentDate: Scalars['ISOCalendarDate']['input'];
	readonly fulfillmentLocationId?: InputMaybe<Scalars['String']['input']>;
	readonly fulfillmentStatus: SalesOrderFulfillmentPlannedStatus;
	readonly handlingMethodOverrideId?: InputMaybe<Scalars['String']['input']>;
	readonly lines: ReadonlyArray<SalesOrderLineInput>;
	readonly requestedDate?: InputMaybe<Scalars['ISOCalendarDate']['input']>;
	readonly routeId: Scalars['String']['input'];
};

/** Sales Order Fulfillment Planned Statuses */
export const SalesOrderFulfillmentPlannedStatus = {
	estimated: 'estimated',
	scheduled: 'scheduled',
} as const;

export type SalesOrderFulfillmentPlannedStatus =
	(typeof SalesOrderFulfillmentPlannedStatus)[keyof typeof SalesOrderFulfillmentPlannedStatus];
/** Sales Order fulfillment state enumerations */
export const SalesOrderFulfillmentState = {
	/** Completed */
	completed: 'completed',
	/** Open */
	open: 'open',
	/** Partially Fulfilled */
	partiallyFulfilled: 'partiallyFulfilled',
} as const;

export type SalesOrderFulfillmentState =
	(typeof SalesOrderFulfillmentState)[keyof typeof SalesOrderFulfillmentState];
/** Sales Order Fulfillment Statuses */
export const SalesOrderFulfillmentStatus = {
	asSoonAsPossible: 'asSoonAsPossible',
	customerWillCall: 'customerWillCall',
	estimated: 'estimated',
	scheduled: 'scheduled',
} as const;

export type SalesOrderFulfillmentStatus =
	(typeof SalesOrderFulfillmentStatus)[keyof typeof SalesOrderFulfillmentStatus];
/** Sales Order Line Component Input */
export type SalesOrderLineComponentInput = {
	readonly itemId: Scalars['String']['input'];
	readonly quantity: Scalars['Int']['input'];
	readonly salesDiscounts?: InputMaybe<ReadonlyArray<SalesOrderLineSalesDiscountInput>>;
};

/** Sales Order Line Direct Ship Input */
export type SalesOrderLineDirectShipInput = {
	readonly comments?: InputMaybe<Scalars['String']['input']>;
	readonly components?: InputMaybe<ReadonlyArray<SalesOrderLineComponentInput>>;
	readonly externalIdentifier?: InputMaybe<Scalars['String']['input']>;
	readonly itemId: Scalars['String']['input'];
	readonly price?: InputMaybe<Scalars['Float']['input']>;
	readonly priceVarianceExceptionReasonId?: InputMaybe<Scalars['String']['input']>;
	/** Supports kits, packages, stock, and special order products */
	readonly purchaseOrderCreationDetails: SalesOrderLinePurchaseOrderCreationDetailsInput;
	readonly quantityOrdered: Scalars['Int']['input'];
	readonly reference?: InputMaybe<Scalars['String']['input']>;
	readonly salesDiscounts?: InputMaybe<ReadonlyArray<SalesOrderLineSalesDiscountInput>>;
	readonly specialOrderConfiguration?: InputMaybe<SalesOrderLineSpecialOrderConfigurationInput>;
	readonly warranties?: InputMaybe<ReadonlyArray<SalesOrderLineProtectionInput>>;
};

/** Sales Order Line Input */
export type SalesOrderLineInput = {
	readonly asIsReferenceNumber?: InputMaybe<Scalars['String']['input']>;
	readonly comments?: InputMaybe<Scalars['String']['input']>;
	readonly components?: InputMaybe<ReadonlyArray<SalesOrderLineComponentInput>>;
	readonly externalIdentifier?: InputMaybe<Scalars['String']['input']>;
	readonly itemId: Scalars['String']['input'];
	readonly price?: InputMaybe<Scalars['Float']['input']>;
	readonly priceVarianceExceptionReasonId?: InputMaybe<Scalars['String']['input']>;
	/** Required for special orders */
	readonly purchaseOrderCreationDetails?: InputMaybe<SalesOrderLinePurchaseOrderCreationDetailsInput>;
	readonly quantityOrdered: Scalars['Int']['input'];
	readonly reference?: InputMaybe<Scalars['String']['input']>;
	readonly salesDiscounts?: InputMaybe<ReadonlyArray<SalesOrderLineSalesDiscountInput>>;
	readonly specialOrderConfiguration?: InputMaybe<SalesOrderLineSpecialOrderConfigurationInput>;
	readonly stockLocationId?: InputMaybe<Scalars['String']['input']>;
	readonly warranties?: InputMaybe<ReadonlyArray<SalesOrderLineProtectionInput>>;
};

/** Sales Order Line Protection Input */
export type SalesOrderLineProtectionInput = { readonly protectionId: Scalars['String']['input'] };

/** Sales Order Line Purchase Order Creation Details Input */
export type SalesOrderLinePurchaseOrderCreationDetailsInput = {
	readonly shippingInstructions?: InputMaybe<Scalars['String']['input']>;
	readonly vendorModelNumber?: InputMaybe<Scalars['String']['input']>;
	readonly vendorShipFromId: Scalars['String']['input'];
};

/** Sales Order Line Sales Discount Input */
export type SalesOrderLineSalesDiscountInput = {
	readonly overrideAmount?: InputMaybe<Scalars['Float']['input']>;
	readonly salesDiscountId: Scalars['String']['input'];
};

/** Sales Order Line Special Order Configuration Input */
export type SalesOrderLineSpecialOrderConfigurationInput = {
	readonly options: ReadonlyArray<SalesOrderLineSpecialOrderConfigurationOptionInput>;
	readonly specialOrderDetails?: InputMaybe<Scalars['String']['input']>;
	readonly templateId: Scalars['String']['input'];
};

/** Sales Order Line Special Order Configuration Option Input */
export type SalesOrderLineSpecialOrderConfigurationOptionInput = {
	readonly freeText?: InputMaybe<Scalars['String']['input']>;
	readonly optionId?: InputMaybe<Scalars['String']['input']>;
	readonly optionTypeId: Scalars['String']['input'];
	readonly price?: InputMaybe<Scalars['Float']['input']>;
	readonly templateOptionTypeId: Scalars['String']['input'];
};

export type SalesOrderTermsAgreedInput = {
	readonly date: Scalars['ISOCalendarDate']['input'];
	readonly time: Scalars['ISOTime']['input'];
	readonly useSignature: Scalars['Boolean']['input'];
	/** IPv4 or IPv6 address format */
	readonly userIpAddress: Scalars['String']['input'];
};

export const SearchDuplicatesFailureReason = {
	/** The number of results exceeds the page size */
	exceedsPageSize: 'exceedsPageSize',
} as const;

export type SearchDuplicatesFailureReason =
	(typeof SearchDuplicatesFailureReason)[keyof typeof SearchDuplicatesFailureReason];
/** SendgridSettings Create Input */
export type SendgridSettingsCreateInput = { readonly apiKey: Scalars['String']['input'] };

/** SendgridSettings Update Input */
export type SendgridSettingsUpdateInput = {
	readonly apiKey?: InputMaybe<Scalars['String']['input']>;
};

/** Settings update Input */
export type SettingsUpdateInput = {
	readonly allowCartSubmissionAsQuote?: InputMaybe<Scalars['Boolean']['input']>;
	readonly allowCartSubmissionAsSalesOrder?: InputMaybe<Scalars['Boolean']['input']>;
	readonly cartPriceChangeValidForDays?: InputMaybe<Scalars['Int']['input']>;
	readonly cxmSettings?: InputMaybe<CXMSettingsInput>;
	readonly emailSender?: InputMaybe<EmailSenderKind>;
	readonly inactivityTimeoutSettings?: InputMaybe<InactivityTimeoutSettingsUpdateInput>;
	readonly itemImagesSettings?: InputMaybe<ItemImagesSettingsInput>;
	readonly quoteOpportunityTransitionStageId?: InputMaybe<Scalars['String']['input']>;
	readonly salesOrderOpportunityTransitionStageId?: InputMaybe<Scalars['String']['input']>;
};

/** Signature format */
export const SignatureFormat = { bmp: 'bmp', coordinates: 'coordinates', png: 'png' } as const;

export type SignatureFormat = (typeof SignatureFormat)[keyof typeof SignatureFormat];
/** SmtpSettings Create Input */
export type SmtpSettingsCreateInput = {
	readonly allowAlternateSenderAddress?: InputMaybe<Scalars['Boolean']['input']>;
	readonly password: Scalars['String']['input'];
	readonly port: Scalars['Int']['input'];
	readonly server: Scalars['String']['input'];
	readonly username: Scalars['String']['input'];
};

/** SmtpSettings Update Input */
export type SmtpSettingsUpdateInput = {
	readonly allowAlternateSenderAddress?: InputMaybe<Scalars['Boolean']['input']>;
	readonly password?: InputMaybe<Scalars['String']['input']>;
	readonly port?: InputMaybe<Scalars['Int']['input']>;
	readonly server?: InputMaybe<Scalars['String']['input']>;
	readonly username?: InputMaybe<Scalars['String']['input']>;
};

/** Source types */
export const SourceType = {
	admin: 'admin',
	app: 'app',
	unauthenticated: 'unauthenticated',
	user: 'user',
} as const;

export type SourceType = (typeof SourceType)[keyof typeof SourceType];
/** String comparison input type */
export type StringComparisonInput = {
	readonly eq?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']['input']>>>;
	readonly startsWith?: InputMaybe<Scalars['String']['input']>;
};

/** Basis Enumerations */
export const SuggestionBasis = { collection: 'collection', group: 'group' } as const;

export type SuggestionBasis = (typeof SuggestionBasis)[keyof typeof SuggestionBasis];
export type SynchronySettingsCreateInput = { readonly environment: SynchronySettingsEnvironment };

export const SynchronySettingsEnvironment = { LIVE: 'LIVE', TEST: 'TEST' } as const;

export type SynchronySettingsEnvironment =
	(typeof SynchronySettingsEnvironment)[keyof typeof SynchronySettingsEnvironment];
export type SynchronySettingsUpdateInput = {
	readonly environment?: InputMaybe<SynchronySettingsEnvironment>;
};

export type TakeOrderCashPaymentInput = {
	readonly amount: Scalars['Float']['input'];
	readonly locationId: Scalars['String']['input'];
	readonly orderId: Scalars['String']['input'];
};

export type TakeOrderCheckPaymentInput = {
	readonly amount: Scalars['Float']['input'];
	readonly checkNumber: Scalars['String']['input'];
	/** Comments will be saved to the order audit logs */
	readonly comments?: InputMaybe<Scalars['String']['input']>;
	readonly locationId: Scalars['String']['input'];
	readonly orderId: Scalars['String']['input'];
};

export type TakeOrderCreditCardAuthorizationToCaptureInput = {
	readonly amountRequested: Scalars['Float']['input'];
	/** Bank identification number of card, required if fraud analysis is active at location */
	readonly cardBin?: InputMaybe<Scalars['String']['input']>;
	readonly cardBrand: CardBrands;
	readonly cardExpirationDate?: InputMaybe<Scalars['Date']['input']>;
	readonly cardLastFour: Scalars['String']['input'];
	readonly cardToken: Scalars['String']['input'];
	readonly isEcommerce?: InputMaybe<Scalars['Boolean']['input']>;
	readonly locationId: Scalars['ID']['input'];
	readonly orderId: Scalars['ID']['input'];
	readonly referenceNumber: Scalars['String']['input'];
	readonly securityCode?: InputMaybe<Scalars['String']['input']>;
	/**
	 * Session id and browser IP address of user captured during authorization, required if fraud analysis is active at
	 * location and isEcommerce is true
	 */
	readonly webFraudAnalysis?: InputMaybe<OrderCreditCardPaymentFraudAnalysisInput>;
};

export type TakeOrderCreditCardPaymentAtTerminalInput = {
	readonly amount: Scalars['Float']['input'];
	readonly locationId: Scalars['ID']['input'];
	readonly orderId: Scalars['ID']['input'];
	readonly terminalId: Scalars['ID']['input'];
};

export type TakeOrderCreditCardPaymentWithTokenInput = {
	readonly allowPartialApprovals?: InputMaybe<Scalars['Boolean']['input']>;
	readonly amountRequested: Scalars['Float']['input'];
	readonly avsData?: InputMaybe<AvsDataInput>;
	readonly cardExpirationDate?: InputMaybe<Scalars['Date']['input']>;
	readonly cardToken: Scalars['String']['input'];
	readonly isEcommerce?: InputMaybe<Scalars['Boolean']['input']>;
	readonly locationId: Scalars['ID']['input'];
	readonly orderId: Scalars['ID']['input'];
	readonly securityCode?: InputMaybe<Scalars['String']['input']>;
	/**
	 * Session id and browser IP address of user captured during authorization, required if fraud analysis is active at
	 * location and isEcommerce is true
	 */
	readonly webFraudAnalysis?: InputMaybe<OrderCreditCardPaymentFraudAnalysisInput>;
};

/** Task Status Enumerations */
export const TaskStatus = {
	completed: 'completed',
	completedNoUpdate: 'completedNoUpdate',
	failed: 'failed',
	started: 'started',
} as const;

export type TaskStatus = (typeof TaskStatus)[keyof typeof TaskStatus];
export type UpdateCustomerInput = {
	/** Indicates if the customer has opted in to receive solicitations. */
	readonly allowSoliciting?: InputMaybe<Scalars['Boolean']['input']>;
	/** The customer's trade type. Value must already exist in the ERP. */
	readonly customerTradeType?: InputMaybe<Scalars['String']['input']>;
	/**
	 * Customer's email addresses. Maximum number of emails is 100. If this property is passed in the request, it will
	 * replace all existing email addresses associated with this address. There is currently no method to update
	 * individual pre-existing email addresses.
	 */
	readonly emails?: InputMaybe<ReadonlyArray<CustomerEmailInput>>;
	/** Customer's name. */
	readonly name?: InputMaybe<CustomerNameUpdateInput>;
	/**
	 * Customer's phone numbers. If this property is passed in the request, it will replace all existing phone numbers
	 * associated with this address. There is currently no method to update individual pre-existing phone numbers.
	 */
	readonly phones?: InputMaybe<ReadonlyArray<CustomerPhoneInput>>;
	/** The customer's selected method of statement delivery for Installment and Revolving plans. */
	readonly statementDeliveryMethod?: InputMaybe<CustomerStatementDeliveryMethod>;
};

export const UserAuthenticationLogEventKind = {
	adminSignIn: 'adminSignIn',
	adminSignOut: 'adminSignOut',
	passwordChange: 'passwordChange',
	passwordReset: 'passwordReset',
	passwordResetCanceled: 'passwordResetCanceled',
	passwordResetRequested: 'passwordResetRequested',
	signIn: 'signIn',
	signOut: 'signOut',
} as const;

export type UserAuthenticationLogEventKind =
	(typeof UserAuthenticationLogEventKind)[keyof typeof UserAuthenticationLogEventKind];
export const UserAuthenticationLogFailureReason = {
	disabledUser: 'disabledUser',
	disabledWorkspace: 'disabledWorkspace',
	entraIdLinkageFailure: 'entraIdLinkageFailure',
	invalidChallengeResponse: 'invalidChallengeResponse',
	invalidEntraIdConfiguration: 'invalidEntraIdConfiguration',
	invalidNewPassword: 'invalidNewPassword',
	invalidPassword: 'invalidPassword',
	invalidResetToken: 'invalidResetToken',
	maximumResetRequestsExceeded: 'maximumResetRequestsExceeded',
	missingResetRegistration: 'missingResetRegistration',
	missingUser: 'missingUser',
	missingUserStaffId: 'missingUserStaffId',
	missingWorkspace: 'missingWorkspace',
	tokenVerificationFailure: 'tokenVerificationFailure',
	unsupportedERPVersion: 'unsupportedERPVersion',
} as const;

export type UserAuthenticationLogFailureReason =
	(typeof UserAuthenticationLogFailureReason)[keyof typeof UserAuthenticationLogFailureReason];
export const UserAuthenticationLogQuerySort = {
	createdAt_ASC: 'createdAt_ASC',
	createdAt_DESC: 'createdAt_DESC',
} as const;

export type UserAuthenticationLogQuerySort =
	(typeof UserAuthenticationLogQuerySort)[keyof typeof UserAuthenticationLogQuerySort];
export const UserAuthenticationMethod = {
	/** Sign-in with Entra ID */
	entraId: 'entraId',
	/** User onboarding */
	onboarding: 'onboarding',
	/** Sign-in with password */
	password: 'password',
} as const;

export type UserAuthenticationMethod =
	(typeof UserAuthenticationMethod)[keyof typeof UserAuthenticationMethod];
export type UserCreateInput = {
	readonly email: Scalars['String']['input'];
	readonly familyName: Scalars['String']['input'];
	readonly givenName: Scalars['String']['input'];
	readonly staffId: Scalars['String']['input'];
	readonly workspaceRoleIds: ReadonlyArray<Scalars['String']['input']>;
};

export const UserGroupQuerySort = { name_ASC: 'name_ASC', name_DESC: 'name_DESC' } as const;

export type UserGroupQuerySort = (typeof UserGroupQuerySort)[keyof typeof UserGroupQuerySort];
export const UserQuerySort = {
	createdAt_ASC: 'createdAt_ASC',
	createdAt_DESC: 'createdAt_DESC',
	email_ASC: 'email_ASC',
	email_DESC: 'email_DESC',
	familyName_ASC: 'familyName_ASC',
	familyName_DESC: 'familyName_DESC',
	givenName_ASC: 'givenName_ASC',
	givenName_DESC: 'givenName_DESC',
	lastLogin_ASC: 'lastLogin_ASC',
	lastLogin_DESC: 'lastLogin_DESC',
	staffId_ASC: 'staffId_ASC',
	staffId_DESC: 'staffId_DESC',
	updatedAt_ASC: 'updatedAt_ASC',
	updatedAt_DESC: 'updatedAt_DESC',
} as const;

export type UserQuerySort = (typeof UserQuerySort)[keyof typeof UserQuerySort];
export type UserSecureUpdateInput = {
	readonly email?: InputMaybe<Scalars['String']['input']>;
	readonly familyName?: InputMaybe<Scalars['String']['input']>;
	readonly givenName?: InputMaybe<Scalars['String']['input']>;
	readonly staffId?: InputMaybe<Scalars['String']['input']>;
};

/** A Protection Plan instance for validation. */
export type ValidateProtectionPlanEntityInput = {
	/** Aggregated extended line item price from all itemIds. */
	readonly aggregatedItemPrice: Scalars['Float']['input'];
	/** Aggregated extended line item quantity from all itemIds. */
	readonly aggregatedItemQuantity: Scalars['Int']['input'];
	readonly items: ReadonlyArray<ProtectionPlanValidateItemInput>;
	readonly protectionPlanId: Scalars['String']['input'];
	readonly sellingLocationId: Scalars['ID']['input'];
};

/** Vertex Settings Create Input */
export type VertexSettingsCreateInput = {
	readonly clientId: Scalars['String']['input'];
	readonly clientSecret: Scalars['String']['input'];
	readonly clientSubdomain: Scalars['String']['input'];
};

/** Vertex Settings Update Input Null is not allowed */
export type VertexSettingsUpdateInput = {
	readonly clientId?: InputMaybe<Scalars['String']['input']>;
	readonly clientSecret?: InputMaybe<Scalars['String']['input']>;
	readonly clientSubdomain?: InputMaybe<Scalars['String']['input']>;
};

/** Workspace Create Input */
export type WorkspaceCreateInput = {
	readonly clientId: Scalars['String']['input'];
	readonly country: Scalars['String']['input'];
	readonly features?: InputMaybe<ReadonlyArray<WorkspaceFeatureInput>>;
	readonly isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
	readonly isLive?: InputMaybe<Scalars['Boolean']['input']>;
	readonly name: Scalars['String']['input'];
};

/** Workspace Feature Input to change the enabled/disabled state of a workspace feature. */
export type WorkspaceFeatureInput = {
	readonly isActive: Scalars['Boolean']['input'];
	readonly name: WorkspaceFeatureName;
};

export const WorkspaceFeatureName = {
	addressVerificationService: 'addressVerificationService',
	cxm: 'cxm',
	paymentIntegrations: 'paymentIntegrations',
} as const;

export type WorkspaceFeatureName = (typeof WorkspaceFeatureName)[keyof typeof WorkspaceFeatureName];
export const WorkspacePermissionName = {
	allowCheckoutAfterRemovalOfProtectionPlans: 'allowCheckoutAfterRemovalOfProtectionPlans',
	/**
	 * Allows the user to omit reason codes or comments when submitting an order. Currently, applies to the price variance
	 * reason code and comment and the delivery charge override reason code. For example, one could omit the
	 * priceVarianceExceptionReasonId when overriding the price of an item, and still be able to successfully submit the
	 * order.
	 */
	allowOrderSubmissionWithoutReasonCodeOrComment: 'allowOrderSubmissionWithoutReasonCodeOrComment',
	appsCxmManagerView: 'appsCxmManagerView',
	appsItemBrandView: 'appsItemBrandView',
	appsItemVendorView: 'appsItemVendorView',
	orderDeliveryChargeOverride: 'orderDeliveryChargeOverride',
	orderFulfillmentHandlingMethodOverride: 'orderFulfillmentHandlingMethodOverride',
	orderLineExceptionOverride: 'orderLineExceptionOverride',
	orderLineProtectionPlanManagerOnlyApply: 'orderLineProtectionPlanManagerOnlyApply',
	orderLineSalesDiscountManagerOnlyApply: 'orderLineSalesDiscountManagerOnlyApply',
	orderMaximumFulfillmentsOverride: 'orderMaximumFulfillmentsOverride',
	workspaceAdminister: 'workspaceAdminister',
	workspaceExceptionAuthorize: 'workspaceExceptionAuthorize',
} as const;

export type WorkspacePermissionName =
	(typeof WorkspacePermissionName)[keyof typeof WorkspacePermissionName];
export const WorkspacePermissionType = { assigned: 'assigned', inherited: 'inherited' } as const;

export type WorkspacePermissionType =
	(typeof WorkspacePermissionType)[keyof typeof WorkspacePermissionType];
/** Workspace Sort Enumerations */
export const WorkspaceQuerySort = {
	/** Client ID */
	clientId_ASC: 'clientId_ASC',
	/** Client ID Descending */
	clientId_DESC: 'clientId_DESC',
	/** Created Timestamp */
	createdAt_ASC: 'createdAt_ASC',
	/** Created Timestamp Descending */
	createdAt_DESC: 'createdAt_DESC',
	/** ID */
	id_ASC: 'id_ASC',
	/** ID Descending */
	id_DESC: 'id_DESC',
	/** Workspace Name */
	name_ASC: 'name_ASC',
	/** Workspace Name Descending */
	name_DESC: 'name_DESC',
	/** Updated Timestamp */
	updatedAt_ASC: 'updatedAt_ASC',
	/** Updated Timestamp Descending */
	updatedAt_DESC: 'updatedAt_DESC',
} as const;

export type WorkspaceQuerySort = (typeof WorkspaceQuerySort)[keyof typeof WorkspaceQuerySort];
export type WorkspaceRoleCreateInput = {
	readonly name: Scalars['String']['input'];
	readonly permissions: ReadonlyArray<Scalars['String']['input']>;
	readonly userGroupId: Scalars['String']['input'];
};

/** Workspace Role sort enumerations */
export const WorkspaceRoleSort = {
	/** Created At */
	createdAt_ASC: 'createdAt_ASC',
	/** Created At Descending */
	createdAt_DESC: 'createdAt_DESC',
	/** Name */
	name_ASC: 'name_ASC',
	/** Name Descending */
	name_DESC: 'name_DESC',
	/** Updated At */
	updatedAt_ASC: 'updatedAt_ASC',
	/** Updated At Descending */
	updatedAt_DESC: 'updatedAt_DESC',
} as const;

export type WorkspaceRoleSort = (typeof WorkspaceRoleSort)[keyof typeof WorkspaceRoleSort];
export type WorkspaceRoleUpdateInput = {
	readonly name?: InputMaybe<Scalars['String']['input']>;
	readonly permissions?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
	readonly userGroupId?: InputMaybe<Scalars['String']['input']>;
};

/** Workspace Update Input */
export type WorkspaceUpdateInput = {
	readonly clientId?: InputMaybe<Scalars['String']['input']>;
	readonly country?: InputMaybe<Scalars['String']['input']>;
	readonly features?: InputMaybe<ReadonlyArray<WorkspaceFeatureInput>>;
	readonly isLive?: InputMaybe<Scalars['Boolean']['input']>;
	readonly name?: InputMaybe<Scalars['String']['input']>;
};

export type useGetMeDetails_MeFragment = {
	readonly __typename?: 'Me';
	readonly id: string;
	readonly givenName: string;
	readonly familyName: string;
	readonly email: string;
	readonly staff: { readonly __typename?: 'Staff'; readonly id: string } | null;
	readonly workspace: {
		readonly __typename?: 'Workspace';
		readonly id: string;
		readonly name: string;
		readonly country: string;
		readonly client: { readonly __typename?: 'Client'; readonly id: string } | null;
		readonly erpDetails: {
			readonly __typename?: 'WorkspaceErpDetails';
			readonly version: string;
			readonly clientNumber: number;
			readonly patchLevel: number;
		} | null;
	} | null;
};

export type GetMeDataVariables = Exact<{ [key: string]: never }>;

export type GetMeDataResult = {
	readonly __typename?: 'Query';
	readonly me: {
		readonly __typename?: 'Me';
		readonly id: string;
		readonly givenName: string;
		readonly familyName: string;
		readonly email: string;
		readonly staff: { readonly __typename?: 'Staff'; readonly id: string } | null;
		readonly workspace: {
			readonly __typename?: 'Workspace';
			readonly id: string;
			readonly name: string;
			readonly country: string;
			readonly client: { readonly __typename?: 'Client'; readonly id: string } | null;
			readonly erpDetails: {
				readonly __typename?: 'WorkspaceErpDetails';
				readonly version: string;
				readonly clientNumber: number;
				readonly patchLevel: number;
			} | null;
		} | null;
	} | null;
};

export type useInactivityTimeout_ApplicationTimeoutSettingsFragment = {
	readonly __typename?: 'ApplicationTimeoutSettings';
	readonly inactivityPeriod: number;
	readonly warningPeriod: number;
};

type useInfiniteQuery_QueryResultFragment_ActivityCommentsQueryResult = {
	readonly __typename?: 'ActivityCommentsQueryResult';
	readonly meta: {
		readonly __typename?: 'QueryMeta';
		readonly page: number;
		readonly totalPages: number;
	};
};

type useInfiniteQuery_QueryResultFragment_ActivityQueryResult = {
	readonly __typename?: 'ActivityQueryResult';
	readonly meta: {
		readonly __typename?: 'QueryMeta';
		readonly page: number;
		readonly totalPages: number;
	};
};

type useInfiniteQuery_QueryResultFragment_ActivityStageQueryResult = {
	readonly __typename?: 'ActivityStageQueryResult';
	readonly meta: {
		readonly __typename?: 'QueryMeta';
		readonly page: number;
		readonly totalPages: number;
	};
};

type useInfiniteQuery_QueryResultFragment_AdminUserQueryResult = {
	readonly __typename?: 'AdminUserQueryResult';
	readonly meta: {
		readonly __typename?: 'QueryMeta';
		readonly page: number;
		readonly totalPages: number;
	};
};

type useInfiniteQuery_QueryResultFragment_AdyenConfiguredStorisLocationQueryResult = {
	readonly __typename?: 'AdyenConfiguredStorisLocationQueryResult';
	readonly meta: {
		readonly __typename?: 'QueryMeta';
		readonly page: number;
		readonly totalPages: number;
	};
};

type useInfiniteQuery_QueryResultFragment_AllActiveProtectionPlansQueryResult = {
	readonly __typename?: 'AllActiveProtectionPlansQueryResult';
	readonly meta: {
		readonly __typename?: 'QueryMeta';
		readonly page: number;
		readonly totalPages: number;
	};
};

type useInfiniteQuery_QueryResultFragment_AppQueryResult = {
	readonly __typename?: 'AppQueryResult';
	readonly meta: {
		readonly __typename?: 'QueryMeta';
		readonly page: number;
		readonly totalPages: number;
	};
};

type useInfiniteQuery_QueryResultFragment_AppRegistrationQueryResult = {
	readonly __typename?: 'AppRegistrationQueryResult';
	readonly meta: {
		readonly __typename?: 'QueryMeta';
		readonly page: number;
		readonly totalPages: number;
	};
};

type useInfiniteQuery_QueryResultFragment_BrandQueryResult = {
	readonly __typename?: 'BrandQueryResult';
	readonly meta: {
		readonly __typename?: 'QueryMeta';
		readonly page: number;
		readonly totalPages: number;
	};
};

type useInfiniteQuery_QueryResultFragment_CartQueryResult = {
	readonly __typename?: 'CartQueryResult';
	readonly meta: {
		readonly __typename?: 'QueryMeta';
		readonly page: number;
		readonly totalPages: number;
	};
};

type useInfiniteQuery_QueryResultFragment_CollectionQueryResult = {
	readonly __typename?: 'CollectionQueryResult';
	readonly meta: {
		readonly __typename?: 'QueryMeta';
		readonly page: number;
		readonly totalPages: number;
	};
};

type useInfiniteQuery_QueryResultFragment_CompletedSalesOrderQueryResult = {
	readonly __typename?: 'CompletedSalesOrderQueryResult';
	readonly meta: {
		readonly __typename?: 'QueryMeta';
		readonly page: number;
		readonly totalPages: number;
	};
};

type useInfiniteQuery_QueryResultFragment_DeliveryChargeOverrideReasonQueryResult = {
	readonly __typename?: 'DeliveryChargeOverrideReasonQueryResult';
	readonly meta: {
		readonly __typename?: 'QueryMeta';
		readonly page: number;
		readonly totalPages: number;
	};
};

type useInfiniteQuery_QueryResultFragment_ElavonConfiguredStorisLocationQueryResult = {
	readonly __typename?: 'ElavonConfiguredStorisLocationQueryResult';
	readonly meta: {
		readonly __typename?: 'QueryMeta';
		readonly page: number;
		readonly totalPages: number;
	};
};

type useInfiniteQuery_QueryResultFragment_EmvTerminalQueryResult = {
	readonly __typename?: 'EmvTerminalQueryResult';
	readonly meta: {
		readonly __typename?: 'QueryMeta';
		readonly page: number;
		readonly totalPages: number;
	};
};

type useInfiniteQuery_QueryResultFragment_EntraIdConfigurationQueryResult = {
	readonly __typename?: 'EntraIdConfigurationQueryResult';
	readonly meta: {
		readonly __typename?: 'QueryMeta';
		readonly page: number;
		readonly totalPages: number;
	};
};

type useInfiniteQuery_QueryResultFragment_ExceptionReasonQueryResult = {
	readonly __typename?: 'ExceptionReasonQueryResult';
	readonly meta: {
		readonly __typename?: 'QueryMeta';
		readonly page: number;
		readonly totalPages: number;
	};
};

type useInfiniteQuery_QueryResultFragment_FiservConfiguredStorisLocationQueryResult = {
	readonly __typename?: 'FiservConfiguredStorisLocationQueryResult';
	readonly meta: {
		readonly __typename?: 'QueryMeta';
		readonly page: number;
		readonly totalPages: number;
	};
};

type useInfiniteQuery_QueryResultFragment_HandlingMethodQueryResult = {
	readonly __typename?: 'HandlingMethodQueryResult';
	readonly meta: {
		readonly __typename?: 'QueryMeta';
		readonly page: number;
		readonly totalPages: number;
	};
};

type useInfiniteQuery_QueryResultFragment_ItemLocationInventoriesResult = {
	readonly __typename?: 'ItemLocationInventoriesResult';
	readonly meta: {
		readonly __typename?: 'QueryMeta';
		readonly page: number;
		readonly totalPages: number;
	};
};

type useInfiniteQuery_QueryResultFragment_ItemQueryResult = {
	readonly __typename?: 'ItemQueryResult';
	readonly meta: {
		readonly __typename?: 'QueryMeta';
		readonly page: number;
		readonly totalPages: number;
	};
};

type useInfiniteQuery_QueryResultFragment_ItemRelatedItemsResult = {
	readonly __typename?: 'ItemRelatedItemsResult';
	readonly meta: {
		readonly __typename?: 'QueryMeta';
		readonly page: number;
		readonly totalPages: number;
	};
};

type useInfiniteQuery_QueryResultFragment_LocationQueryResult = {
	readonly __typename?: 'LocationQueryResult';
	readonly meta: {
		readonly __typename?: 'QueryMeta';
		readonly page: number;
		readonly totalPages: number;
	};
};

type useInfiniteQuery_QueryResultFragment_OpenSalesOrderQueryResult = {
	readonly __typename?: 'OpenSalesOrderQueryResult';
	readonly meta: {
		readonly __typename?: 'QueryMeta';
		readonly page: number;
		readonly totalPages: number;
	};
};

type useInfiniteQuery_QueryResultFragment_OpportunityCommentsQueryResult = {
	readonly __typename?: 'OpportunityCommentsQueryResult';
	readonly meta: {
		readonly __typename?: 'QueryMeta';
		readonly page: number;
		readonly totalPages: number;
	};
};

type useInfiniteQuery_QueryResultFragment_OpportunityProjectTypeQueryResult = {
	readonly __typename?: 'OpportunityProjectTypeQueryResult';
	readonly meta: {
		readonly __typename?: 'QueryMeta';
		readonly page: number;
		readonly totalPages: number;
	};
};

type useInfiniteQuery_QueryResultFragment_OpportunityQueryResult = {
	readonly __typename?: 'OpportunityQueryResult';
	readonly meta: {
		readonly __typename?: 'QueryMeta';
		readonly page: number;
		readonly totalPages: number;
	};
};

type useInfiniteQuery_QueryResultFragment_OpportunityStageQueryResult = {
	readonly __typename?: 'OpportunityStageQueryResult';
	readonly meta: {
		readonly __typename?: 'QueryMeta';
		readonly page: number;
		readonly totalPages: number;
	};
};

type useInfiniteQuery_QueryResultFragment_OrderCommentQueryResult = {
	readonly __typename?: 'OrderCommentQueryResult';
	readonly meta: {
		readonly __typename?: 'QueryMeta';
		readonly page: number;
		readonly totalPages: number;
	};
};

type useInfiniteQuery_QueryResultFragment_ProductCategoryQueryResult = {
	readonly __typename?: 'ProductCategoryQueryResult';
	readonly meta: {
		readonly __typename?: 'QueryMeta';
		readonly page: number;
		readonly totalPages: number;
	};
};

type useInfiniteQuery_QueryResultFragment_ProductGroupQueryResult = {
	readonly __typename?: 'ProductGroupQueryResult';
	readonly meta: {
		readonly __typename?: 'QueryMeta';
		readonly page: number;
		readonly totalPages: number;
	};
};

type useInfiniteQuery_QueryResultFragment_PurchaseOrderItemQueryResult = {
	readonly __typename?: 'PurchaseOrderItemQueryResult';
	readonly meta: {
		readonly __typename?: 'QueryMeta';
		readonly page: number;
		readonly totalPages: number;
	};
};

type useInfiniteQuery_QueryResultFragment_PurchaseOrderQueryResult = {
	readonly __typename?: 'PurchaseOrderQueryResult';
	readonly meta: {
		readonly __typename?: 'QueryMeta';
		readonly page: number;
		readonly totalPages: number;
	};
};

type useInfiniteQuery_QueryResultFragment_PurchaseStatusQueryResult = {
	readonly __typename?: 'PurchaseStatusQueryResult';
	readonly meta: {
		readonly __typename?: 'QueryMeta';
		readonly page: number;
		readonly totalPages: number;
	};
};

type useInfiniteQuery_QueryResultFragment_RegionQueryResult = {
	readonly __typename?: 'RegionQueryResult';
	readonly meta: {
		readonly __typename?: 'QueryMeta';
		readonly page: number;
		readonly totalPages: number;
	};
};

type useInfiniteQuery_QueryResultFragment_RelationshipQueryResult = {
	readonly __typename?: 'RelationshipQueryResult';
	readonly meta: {
		readonly __typename?: 'QueryMeta';
		readonly page: number;
		readonly totalPages: number;
	};
};

type useInfiniteQuery_QueryResultFragment_SalespersonQueryResult = {
	readonly __typename?: 'SalespersonQueryResult';
	readonly meta: {
		readonly __typename?: 'QueryMeta';
		readonly page: number;
		readonly totalPages: number;
	};
};

type useInfiniteQuery_QueryResultFragment_SuggestionQueryResult = {
	readonly __typename?: 'SuggestionQueryResult';
	readonly meta: {
		readonly __typename?: 'QueryMeta';
		readonly page: number;
		readonly totalPages: number;
	};
};

type useInfiniteQuery_QueryResultFragment_UserAuditLogQueryResult = {
	readonly __typename?: 'UserAuditLogQueryResult';
	readonly meta: {
		readonly __typename?: 'QueryMeta';
		readonly page: number;
		readonly totalPages: number;
	};
};

type useInfiniteQuery_QueryResultFragment_UserAuthenticationLogQueryResult = {
	readonly __typename?: 'UserAuthenticationLogQueryResult';
	readonly meta: {
		readonly __typename?: 'QueryMeta';
		readonly page: number;
		readonly totalPages: number;
	};
};

type useInfiniteQuery_QueryResultFragment_UserGroupQueryResult = {
	readonly __typename?: 'UserGroupQueryResult';
	readonly meta: {
		readonly __typename?: 'QueryMeta';
		readonly page: number;
		readonly totalPages: number;
	};
};

type useInfiniteQuery_QueryResultFragment_UserQueryResult = {
	readonly __typename?: 'UserQueryResult';
	readonly meta: {
		readonly __typename?: 'QueryMeta';
		readonly page: number;
		readonly totalPages: number;
	};
};

type useInfiniteQuery_QueryResultFragment_VendorQueryResult = {
	readonly __typename?: 'VendorQueryResult';
	readonly meta: {
		readonly __typename?: 'QueryMeta';
		readonly page: number;
		readonly totalPages: number;
	};
};

type useInfiniteQuery_QueryResultFragment_WorkspaceQueryResult = {
	readonly __typename?: 'WorkspaceQueryResult';
	readonly meta: {
		readonly __typename?: 'QueryMeta';
		readonly page: number;
		readonly totalPages: number;
	};
};

type useInfiniteQuery_QueryResultFragment_WorkspaceRoleAuditLogQueryResult = {
	readonly __typename?: 'WorkspaceRoleAuditLogQueryResult';
	readonly meta: {
		readonly __typename?: 'QueryMeta';
		readonly page: number;
		readonly totalPages: number;
	};
};

type useInfiniteQuery_QueryResultFragment_WorkspaceRoleQueryResult = {
	readonly __typename?: 'WorkspaceRoleQueryResult';
	readonly meta: {
		readonly __typename?: 'QueryMeta';
		readonly page: number;
		readonly totalPages: number;
	};
};

export type useInfiniteQuery_QueryResultFragment =
	| useInfiniteQuery_QueryResultFragment_ActivityCommentsQueryResult
	| useInfiniteQuery_QueryResultFragment_ActivityQueryResult
	| useInfiniteQuery_QueryResultFragment_ActivityStageQueryResult
	| useInfiniteQuery_QueryResultFragment_AdminUserQueryResult
	| useInfiniteQuery_QueryResultFragment_AdyenConfiguredStorisLocationQueryResult
	| useInfiniteQuery_QueryResultFragment_AllActiveProtectionPlansQueryResult
	| useInfiniteQuery_QueryResultFragment_AppQueryResult
	| useInfiniteQuery_QueryResultFragment_AppRegistrationQueryResult
	| useInfiniteQuery_QueryResultFragment_BrandQueryResult
	| useInfiniteQuery_QueryResultFragment_CartQueryResult
	| useInfiniteQuery_QueryResultFragment_CollectionQueryResult
	| useInfiniteQuery_QueryResultFragment_CompletedSalesOrderQueryResult
	| useInfiniteQuery_QueryResultFragment_DeliveryChargeOverrideReasonQueryResult
	| useInfiniteQuery_QueryResultFragment_ElavonConfiguredStorisLocationQueryResult
	| useInfiniteQuery_QueryResultFragment_EmvTerminalQueryResult
	| useInfiniteQuery_QueryResultFragment_EntraIdConfigurationQueryResult
	| useInfiniteQuery_QueryResultFragment_ExceptionReasonQueryResult
	| useInfiniteQuery_QueryResultFragment_FiservConfiguredStorisLocationQueryResult
	| useInfiniteQuery_QueryResultFragment_HandlingMethodQueryResult
	| useInfiniteQuery_QueryResultFragment_ItemLocationInventoriesResult
	| useInfiniteQuery_QueryResultFragment_ItemQueryResult
	| useInfiniteQuery_QueryResultFragment_ItemRelatedItemsResult
	| useInfiniteQuery_QueryResultFragment_LocationQueryResult
	| useInfiniteQuery_QueryResultFragment_OpenSalesOrderQueryResult
	| useInfiniteQuery_QueryResultFragment_OpportunityCommentsQueryResult
	| useInfiniteQuery_QueryResultFragment_OpportunityProjectTypeQueryResult
	| useInfiniteQuery_QueryResultFragment_OpportunityQueryResult
	| useInfiniteQuery_QueryResultFragment_OpportunityStageQueryResult
	| useInfiniteQuery_QueryResultFragment_OrderCommentQueryResult
	| useInfiniteQuery_QueryResultFragment_ProductCategoryQueryResult
	| useInfiniteQuery_QueryResultFragment_ProductGroupQueryResult
	| useInfiniteQuery_QueryResultFragment_PurchaseOrderItemQueryResult
	| useInfiniteQuery_QueryResultFragment_PurchaseOrderQueryResult
	| useInfiniteQuery_QueryResultFragment_PurchaseStatusQueryResult
	| useInfiniteQuery_QueryResultFragment_RegionQueryResult
	| useInfiniteQuery_QueryResultFragment_RelationshipQueryResult
	| useInfiniteQuery_QueryResultFragment_SalespersonQueryResult
	| useInfiniteQuery_QueryResultFragment_SuggestionQueryResult
	| useInfiniteQuery_QueryResultFragment_UserAuditLogQueryResult
	| useInfiniteQuery_QueryResultFragment_UserAuthenticationLogQueryResult
	| useInfiniteQuery_QueryResultFragment_UserGroupQueryResult
	| useInfiniteQuery_QueryResultFragment_UserQueryResult
	| useInfiniteQuery_QueryResultFragment_VendorQueryResult
	| useInfiniteQuery_QueryResultFragment_WorkspaceQueryResult
	| useInfiniteQuery_QueryResultFragment_WorkspaceRoleAuditLogQueryResult
	| useInfiniteQuery_QueryResultFragment_WorkspaceRoleQueryResult;

export type useIsWorkspaceAdmin_MeFragment = {
	readonly __typename?: 'Me';
	readonly id: string;
	readonly permissions: ReadonlyArray<{
		readonly __typename?: 'WorkspacePermission';
		readonly name: WorkspacePermissionName;
	} | null> | null;
};

export type GetMeVariables = Exact<{ [key: string]: never }>;

export type GetMeResult = {
	readonly __typename?: 'Query';
	readonly me: {
		readonly __typename?: 'Me';
		readonly id: string;
		readonly permissions: ReadonlyArray<{
			readonly __typename?: 'WorkspacePermission';
			readonly name: WorkspacePermissionName;
		} | null> | null;
	} | null;
};

export type RefreshAccessTokenVariables = Exact<{ refreshToken: Scalars['String']['input'] }>;

export type RefreshAccessTokenResult = {
	readonly __typename?: 'Mutation';
	readonly accessTokenRefresh:
		| {
				readonly __typename?: 'AccessTokenRefreshSuccess';
				readonly refreshToken: string;
				readonly accessToken: string;
		  }
		| {
				readonly __typename?: 'AuthenticationFailure';
				readonly reason: AuthenticationFailureReason;
		  };
};

export type useWorkspaceFeatures_MeFragment = {
	readonly __typename?: 'Me';
	readonly id: string;
	readonly workspace: {
		readonly __typename?: 'Workspace';
		readonly id: string;
		readonly features: ReadonlyArray<{
			readonly __typename?: 'WorkspaceFeature';
			readonly name: WorkspaceFeatureName;
			readonly isActive: boolean;
		}>;
	} | null;
};

export type GetMeWorkspaceFeaturesVariables = Exact<{ [key: string]: never }>;

export type GetMeWorkspaceFeaturesResult = {
	readonly __typename?: 'Query';
	readonly me: {
		readonly __typename?: 'Me';
		readonly id: string;
		readonly workspace: {
			readonly __typename?: 'Workspace';
			readonly id: string;
			readonly features: ReadonlyArray<{
				readonly __typename?: 'WorkspaceFeature';
				readonly name: WorkspaceFeatureName;
				readonly isActive: boolean;
			}>;
		} | null;
	} | null;
};

export type useWorkspacePermissions_WorkspacePermissionFragment = {
	readonly __typename?: 'WorkspacePermission';
	readonly name: WorkspacePermissionName;
};
